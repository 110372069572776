import React from 'react'

import Card from 'components/Card'
import CardInfo from 'components/CardInfo'

import './stack.css'

let positionTimeout
const deck = localStorage.getItem('deck') || 'cards'

class Stack extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedPosition: null,
      positionClass: ''
    }

    this.doHoverPosition = this.doHoverPosition.bind(this)
    this.hidePosition = this.hidePosition.bind(this)
  }

  componentWillUnmount () {
    clearTimeout(positionTimeout)
  }

  hidePosition () {
    this.setState({
      selectedPosition: null,
      positionClass: 'hide-position'
    })
  }

  doHoverPosition (positionIndex) {
    clearTimeout(positionTimeout)
    const { spread } = this.props
    if (spread !== undefined) {
      const { positions } = spread
      const selectedPosition = positions[positionIndex]
      this.setState({
        selectedPosition,
        positionClass: 'show-position'
      })

      positionTimeout = setTimeout(() => {
        this.hidePosition()
        clearTimeout(positionTimeout)
      }, 1500)
    }
  }

  render () {
    const { selectedPosition, hidePosition, positionClass } = this.state
    const {
      images,
      cardsData,
      allSelected,
      StackData,
      selected,
      selectedOrientation,
      currentSpreadID,
      spread,
      positionMap,
      shuffled,
      hovered,
      // printing,
      viewing,
      viewed,
      handleSelect,
      updateShuffling,
      applyHoverAndView
    } = this.props

    images.Back = `/${deck}/Back.jpg`
    // const numbers = ['O', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII', 'XIII', 'XIV', 'XV', 'XVI', 'XVII', 'XIII', 'XIX', 'XX', 'XXI', 'XXII', 'XXIII']
    const { positions } = spread
    let thisStack = StackData
    const spreadPositions = []
    const thisStackSorted = []
    const hasKeyCard = spread.id === 'sphinx'
    const selectedNumber = 0
    let spreadClass = ''
    const stackMarkup = []
    const infoMarkup = []

    if (allSelected) {
      spreadClass = `spread-ready spread-${currentSpreadID}`
      thisStack = selected.map(id => ({ id }))

      positions.forEach((position) => {
        const stackIndex = position.index - 1
        if (thisStack[stackIndex] !== undefined) {
          thisStackSorted.push(thisStack[stackIndex])
          spreadPositions.push(position)
        }
      })
      // replace stack with stack sorted by spread positions
      thisStack = thisStackSorted
    }

    thisStack.forEach((card, index) => {
      let { orientation } = card
      let isViewing = false
      let indexViewing = 0
      let isSelected = false
      let isViewed = false
      let thisNumber = selectedNumber
      let thisCardData = {}
      const thisInfo = {}
      let thisImages = {}
      let spreadPosition = { title: null }

      if (images.Back !== undefined) {
        thisImages = { Back: images.Back }
      }

      if (card.id !== undefined && selected.indexOf(card.id) >= 0) {
        thisNumber = selected.indexOf(card.id) + 1

        spreadPosition = spreadPositions[index]
        thisInfo.spreadPosition = spreadPositions[index]
        isSelected = true
        thisCardData = cardsData[card.id]
        orientation = selectedOrientation[thisNumber - 1]
        if (images[card.id] !== undefined) {
          thisImages[card.id] = images[card.id]
        }
      } else {
        thisNumber = 0
      }

      if (viewing === card.id) {
        isViewing = true
        indexViewing = selected.length + 1
      }
      if (viewed.indexOf(card.id) >= 0) {
        isViewed = true
      }

      const thisCard = (
        <Card
          key={card.id}
          ref={card.id}
          hasKeyCard={hasKeyCard}
          positions={positionMap[index]}
          shuffled={shuffled}
          spread={spreadClass}
          order={index}
          orientation={orientation}
          hovered={hovered}
          isViewing={isViewing}
          indexViewing={indexViewing}
          isSelected={isSelected}
          isViewed={isViewed}
          selectedNumber={thisNumber}
          spreadPosition={spreadPosition}
          images={thisImages}
          handleSelect={e => handleSelect(card.id, orientation, e)}
          updateShuffling={e => updateShuffling(index, e)}
          applyHoverAndView={e => applyHoverAndView(card.id, e)}
          doHoverPosition={e => this.doHoverPosition(index, e)}
          hidePosition={hidePosition}
          {...thisCardData}
        />
      )

      thisInfo.info = thisCardData
      thisInfo.orientation = orientation
      thisInfo.positionNumber = thisNumber
      thisInfo.images = thisImages

      const thisInfoMarkup = (
        <CardInfo
          key={`${card.id}-info`}
          {...thisInfo}
        />
      )

      if (allSelected) {
        if (selected.indexOf(card.id) >= 0) {
          stackMarkup.push(thisCard)
          infoMarkup.push(thisInfoMarkup)
        }
      } else {
        stackMarkup.push(thisCard)
      }
    })

    return (
      <div>
        <div className='reading'>
          {stackMarkup}
        </div>

        <div className={`selected-position ${positionClass}`}>
          <span>
            {selectedPosition !== null && selectedPosition.title}
          </span>
        </div>

        <div className='reading-notes print-hidden'>
          {infoMarkup}
        </div>
      </div>
    )
  }
}

export default Stack
