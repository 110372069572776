/* eslint camelcase: ["error", {allow: ["UNSAFE_componentWillReceiveProps"]}] */
import React from 'react'
import { navigate } from '@reach/router'
// import Loadable from 'react-loadable';

import Cards from 'components/Cards'
import images from 'data/images.js'
import SpreadInfo from 'components/SpreadInfo'

import getUrlParameter from 'helpers/getUrlParameter'
import getTranslations from 'helpers/getTranslations'

import './main.css'
import './spread.css'
import './sphinx.css'
import './zodiac.css'

// const AsyncCards = Loadable({
//   loader: () => import('components/Cards'),
//   loading: <div />,
// });

let scrollTimer

class Spread extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props)

    const saveData = JSON.parse(sessionStorage.getItem('saveData'))
    let question = null
    if (saveData !== null) {
      question = saveData.question; // eslint-disable-line
    }

    const { spreadID } = this.props

    const urlSpreadID = getUrlParameter('spread')

    let currentSpread = {
      id: urlSpreadID != null ? urlSpreadID : null
    }
    let selected = false

    if (spreadID !== null && spreadID !== undefined) {
      currentSpread = {
        id: spreadID
      }
      selected = true
    }

    this.state = {
      currentSpread,
      selected,
      showSpreadInfo: false,
      showQuestion: false,
      question,
      loadedAllCards: true
    }
    this.selectSpread = this.selectSpread.bind(this)
    this.redoSelect = this.redoSelect.bind(this)
    this.doSpreadInfo = this.doSpreadInfo.bind(this)
    // this.doQuestion = this.doQuestion.bind(this);
    this.updateQuestion = this.updateQuestion.bind(this)
    // this.updateIndicator = this.updateIndicator.bind(this);
    // this.scrollTo = this.scrollTo.bind(this);
  }

  // componentDidMount() {
  //   const el = document.querySelector('.tabs');
  //   const options = {};
  //   M.Tabs.init(el, options);
  // }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const spreadID = getUrlParameter('spread')
    if (spreadID.length <= 0 && nextProps.spreadID === undefined) {
      sessionStorage.removeItem('saveData')
      this.setState({
        currentSpread: {
          id: null
        },
        selected: false
      })
    }
  }

  componentDidUpdate () {
    // const { spreadID } = this.props;
  }

  componentWillUnmount () {
    window.clearTimeout(scrollTimer)
  }

  redoSelect () {
    navigate('/')
    this.setState({
      currentSpread: {
        id: null
      },
      selected: false,
      showQuestion: false,
      question: null
    })
  }

  selectSpread (id, e) {
    const { currentSpread } = this.state
    let { locale } = this.props
    e.preventDefault()
    e.persist()
    locale = locale === undefined ? 'zh' : locale
    const newSpread = currentSpread
    newSpread.id = id

    getTranslations(locale, id).then((data) => {
      const thisSpread = data.spread
      if (thisSpread !== null) {
        this.setState({
          currentSpread: {
            ...thisSpread
          },
          selected: true,
          showSpreadInfo: false
        })
        navigate(`/spread/${id}`)
      }
    })
  }

  doSpreadInfo (id, e) {
    const { currentSpread, showSpreadInfo } = this.state
    let { locale } = this.props
    e.preventDefault()
    e.persist()

    const newSpread = currentSpread
    const showInfo = showSpreadInfo
    newSpread.id = id
    locale = locale === undefined ? 'zh' : locale

    if (!showInfo) {
      getTranslations(locale, id).then((data) => {
        const thisSpread = data.spread
        if (thisSpread !== null) {
          this.setState({
            currentSpread: {
              ...thisSpread
            },
            selected: false,
            showSpreadInfo: !showSpreadInfo,
            showQuestion: false
          })
        }
      })
    } else {
      this.setState({
        showSpreadInfo: !showSpreadInfo,
        showQuestion: false
      })
    }
  }

  updateQuestion (e) {
    const question = e.target.value
    this.setState({
      question
    })
  }

  render () {
    let blurClass = ''
    const {
      selected,
      currentSpread,
      loadedAllCards,
      showQuestion,
      question,
      showSpreadInfo
    } = this.state

    if (selected) {
      blurClass = ''
    }

    const imagesMarkup = []

    Object.keys(images).forEach((key) => {
      imagesMarkup.push(
        <img
          className='hidden-image'
          key={`images-${key}`}
          src={images[key]}
          alt={key}
        />
      )
    })

    return (
      <div className='page-spread'>
        {(currentSpread.id !== null) && (
          <Cards
            blur={blurClass}
            mode='default'
            currentSpread={currentSpread}
            redoSelect={this.redoSelect}
            question={question}
          />
        )}
        {(showSpreadInfo &&
          (
            <SpreadInfo
              spread={currentSpread}
              doSpreadInfo={e => this.doSpreadInfo(currentSpread.id, e)}
              selectSpread={e => this.selectSpread(currentSpread.id, e)}
              updateQuestion={e => this.updateQuestion(e)}
              showQuestion={showQuestion}
            />
          )
        )}

        {(loadedAllCards !== true) &&
          (
            <div className='hidden-images'>
              {imagesMarkup}
            </div>
          )}
      </div>
    )
  }
}

export default Spread
