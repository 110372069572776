import React from 'react'
import { Link } from '@reach/router'

import './notice.css'

class CookiesNotice extends React.Component {
  constructor (props) {
    super(props)

    let hidden = localStorage.getItem('hideCookiesNotice')
    if (hidden === 'true') {
      hidden = true
    }
    this.state = {
      hidden
    }
    this.handleClose = this.handleClose.bind(this)
  }

  handleClose () {
    localStorage.setItem('hideCookiesNotice', 'true')
    this.setState({
      hidden: true
    })
  }

  render () {
    const { hidden } = this.state

    let noticeMarkup = (
      <div className='notice-cookies'>
        <span>
          We use annonymous cookies to understand how you use this app.
          {' '}
          <Link to='/privacy'>Check our Privacy Policy</Link>
          .
        </span>
        <span
          className='close'
          onClick={this.handleClose}
          onKeyPress={this.handleClose}
          role='button'
          tabIndex='0'
        >
          Okay
        </span>
      </div>
    )

    if (hidden) {
      noticeMarkup = ''
    }
    return noticeMarkup
  }
}

export default CookiesNotice
