import React, { useContext, useEffect, useState } from 'react'

import Cards from 'components/Cards'
import spreads from 'data/spreads'
// import cards from 'data/zh/cards.json'
// import images from 'data/images.js'

import './review.css'
import { DB } from 'context/DB'

async function getHistory (db, setState, uuid) {
  if (!db) { return }
  const historyData = await db.transaction(['history'], 'readonly').objectStore('history').get(uuid)
  setState({
    ...historyData
  })
}

const Review = (props) => {
  const { uuid } = props
  const [historyData, setState] = useState(undefined)
  const db = useContext(DB)

  useEffect(() => {
    if (!historyData && db) {
      getHistory(db, setState, uuid)
    }
  }, [db, uuid, historyData])

  if (!historyData) {
    return ''
  }

  const {
    date,
    spreadId
  } = historyData

  let year
  if (date !== undefined) {
    year = new Date(date).getFullYear()
  }

  const currentSpread = spreads[spreadId]

  return (
    <div className='history review'>
      <Cards
        blur=''
        year={year}
        currentSpread={currentSpread}
        mode='review'
        uuid={uuid}
        historyData={historyData}
        // redoSelect={this.redoSelect}
        // question={this.state.question}
      />
    </div>
  )
}

export default Review
