import React, { useEffect, useState, useContext } from 'react'

// import SignIn from 'components/Auth/SignIn'
import { Firebase } from '../../../context/Firebase'
import { GoogleAPI } from 'context/GoogleAPI'
import { User } from 'context/User'
// import userInit from './userInit'
import UserInfo from '../../../components/Auth/UserInfo'
import log from 'lib/log'

const Account = (props) => {
  const user = useContext(User)
  const [state, setState] = useState({
    showForm: false,
    userIsSignedIn: false,
    userSynced: false,
    allowGuest: false
  })

  const firebase = useContext(Firebase)
  const gapi = useContext(GoogleAPI)

  useEffect(() => {
    function updateSigninStatus (isSignedIn) {
      log.debug('signed in?', isSignedIn, user)
      const thisUser = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile()
      log.debug(thisUser, 'updateSigninStatus user')
      if (isSignedIn) {
        setState({
          ...state,
          userIsSignedIn: true
        })
      } else {
        setState({
          ...state,
          userIsSignedIn: false
        })
      }
    }

    if (gapi) {
      log.debug('api', gapi)
      gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus)
      const thisUser = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile()
      log.debug(thisUser, 'user')
      // TODO update user
    }
  }, [gapi, user, state])

  useEffect(() => {
    clearTimeout()
    if (firebase) {
      firebase.auth().onAuthStateChanged((thisUser) => {
        if (thisUser) {
          if (thisUser != null) {
            const {
              displayName, email, photoURL, uid, emailVerified, isAnonymous
            } = thisUser

            const token = thisUser.getIdToken()
            const currentUser = {
              displayName,
              email,
              isAnonymous,
              photoURL,
              uid,
              emailVerified,
              token
            }

            setState({
              user: currentUser,
              userIsSignedIn: true
            })

            const savedUID = localStorage.getItem('user_uuid')
            if (savedUID === null || savedUID !== uid) {
              localStorage.setItem('user_uuid', uid)
              // userSyncAccount(uid, currentUser)
            }
          }
        } else {
          // No user is signed in.
          console.info('show login form')
          setState({
            showForm: true
          })
        }
      })
    }
  }, [firebase])

  // useEffect(() => {

  // }, [state])

  // function userSyncAccount (uid, user) {
  //   const db = firebase.db
  //   // Add a new document in collection "cities"
  //   db.collection('users').doc(uid).set({
  //     ...user
  //   })
  //     .then(() => {
  //       setState({
  //         userSynced: Date.now() / 1000
  //       })

  //       console.info('Document successfully written!')
  //     })
  //     .catch((error) => {
  //       console.info('Error writing document: ', error)
  //     })
  // }

  function oauthLogin () {
    gapi.auth2.getAuthInstance().signIn()
  }

  function oauthLogout () {
    gapi.auth2.getAuthInstance().signOut()
  }

  // function signOut() {
  //   firebase.auth().signOut().then(() => {
  //     setState({
  //       user: userInit,
  //       userIsSignedIn: false,
  //     });
  //   }, (error) => {
  //     console.error('Sign Out Error', error);
  //   });
  // }

  // eslint-disable-next-line class-methods-use-this
  // function renderLoginForm () {
  //   return (
  //     <div className='form'>
  //       <h1>Sign in</h1>
  //     </div>
  //   )
  // }

  const {
    showForm,
    // userSynced,
    userIsSignedIn
    // allowGuest
  } = state

  // let allowThisGuest = allowGuest
  // if (userIsSignedIn) {
  //   allowThisGuest = false
  // }

  if (showForm) {
    return (
      <>
        <br /><br /><br /><br />
        <button onClick={oauthLogin}>Login with Drive</button>
        <button onClick={oauthLogout}>Login with Drive</button>
        {/* <SignIn showForm userIsSignedIn allowGuest={allowThisGuest} /> */}
      </>
    )
  }

  return (
    <div className='sign-in absolute-with-scroll'>
      <UserInfo user={user} />
      {/* <SignIn showForm userIsSignedIn allowGuest={allowThisGuest} /> */}
      {(userIsSignedIn) &&
        (
          <button

            className='waves-effect waves-light btn btn-small'
            // onClick={() => signOut()}
            // onKeyPress={() => signOut()}
          >
            Sign out
          </button>
        )}
    </div>
  )
}

export default Account
