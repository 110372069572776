import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'

import { User } from 'context/User'
// import { DB } from 'context/DB'
import log from 'lib/log'
// import Share from 'components/Share'

import imagesData from 'data/images.js'

import DownloadModal from 'components/Nav/DownloadModal'
import NavUI from './ui'

const Nav = (props) => {
  const {
    // doSave,
    // doDeleteCookies,
    // doLanguageSelect,
    // doShare,
    title = 'HaCker: 駭客塔羅'
    // share
  } = props
  const user = useContext(User)
  // const db = useContext(DB)

  const images = Object.keys(imagesData).map(key => imagesData[key])
  const [accountAnchor, setAccountAnchor] = React.useState(null)
  const [moreAnchor, setMoreAnchor] = React.useState(null)
  const [modal, setModal] = React.useState({
    type: 'download',
    open: false
  })

  const [state, setState] = useState({
    downloadText: 'Enable Offline Mode',
    downloaded: false,
    downloadClass: 'hidden',
    confirmed: false,
    images,
    imagesTotal: images.length,
    currentTotal: 0,
    newDownloads: images.length,
    downloadedTotal: 0,
    imagesDownloaded: [],
    imagesFailed: [],
    initialized: false
  })

  useEffect(() => {
    log.debug('nav updated')
    if (!state.initialized) {
      let downloaded = localStorage.getItem('downloaded')
      let downloadedTotal = localStorage.getItem('downloadedTotal')
      let { newDownloads, currentTotal, downloadText } = state

      if (downloaded === null) {
        downloaded = false
      }
      if (downloadedTotal === null) {
        downloadedTotal = 0
        newDownloads = images.length
      } else {
        newDownloads = images.length - downloadedTotal
        currentTotal = downloadedTotal
        downloadText = 'Update Available'
      }
      // console.log(newDownloads);

      setState({
        downloadText,
        downloaded,
        downloadClass: 'hidden',
        confirmed: false,
        images,
        imagesTotal: images.length,
        currentTotal,
        newDownloads,
        downloadedTotal,
        imagesDownloaded: [],
        imagesFailed: [],
        initialized: true
      })
    }
  }, [state, images])

  function downloadLater () {
    setState((prevState) => {
      return {
        ...prevState,
        confirmed: false,
        downloadClass: 'hidden'
      }
    })
    handleModal('download', false)
  }

  // function confirmDownload () {
  //   const { confirmed, downloaded } = state
  //   if (downloaded) {
  //     setState({
  //       ...state,
  //       downloadClass: 'enabled',
  //       confirmed: !confirmed
  //     })
  //   } else {
  //     setState({
  //       ...state,
  //       confirmed: !confirmed,
  //       downloadClass: ''
  //     })
  //   }
  // }

  function doDownload () {
    const { newDownloads } = state
    if (newDownloads > 0) {
      setState({
        ...state,
        downloadClass: 'downloading'
      })
    } else {
      setState({
        ...state,
        downloadClass: 'hidden',
        downloadText: 'Offline ready'
      })
      handleModal('download', false)
    }
  }

  function handleImageLoaded (url) {
    const { imagesDownloaded, imagesTotal } = state
    imagesDownloaded.push(url)
    const currentTotal = imagesDownloaded.length

    if (currentTotal < imagesTotal) {
      setState({
        ...state,
        imagesDownloaded,
        currentTotal
      })
    } else {
      setState({
        ...state,
        imagesDownloaded,
        currentTotal,
        downloaded: true,
        newDownloads: imagesTotal - imagesDownloaded.length,
        downloadedTotal: imagesDownloaded.length,
        downloadClass: 'completed'
      })
      localStorage.setItem('downloaded', true)
      localStorage.setItem('downloadedTotal', imagesDownloaded.length)
    }
  }

  function handleImageErrored (url) {
    const { imagesFailed } = state
    imagesFailed.push(url)
    setState({ imagesFailed })
  }

  function handleClick (event) {
    setAccountAnchor(event.currentTarget)
  }

  function handleClose () {
    setAccountAnchor(null)
  }

  const handleMore = (event) => {
    setMoreAnchor(event.currentTarget)
  }

  const closeMore = () => {
    setMoreAnchor(null)
  }

  function handleModal (type, open) {
    setModal({
      type,
      open
    })
  }

  const {
    downloadText,
    newDownloads,
    downloadClass,
    // images,
    imagesTotal,
    currentTotal,
    loaderClass
  } = state

  const isLoggedIn = user ? user.isLoggedIn : false

  const uiProps = {
    title,
    isLoggedIn,
    newDownloads,
    accountAnchor,
    moreAnchor,
    handleMore,
    closeMore,
    handleClick,
    handleClose,
    handleModal,
    navigate
  }

  const {
    type,
    open
  } = modal

  const modalProps = {
    open,
    type,
    handleModal,
    downloadLater,
    downloadClass,
    currentTotal,
    images,
    imagesTotal,
    newDownloads,
    downloadText,
    handleImageErrored,
    handleImageLoaded,
    loaderClass,
    doDownload
  }

  return (
    <>
      <NavUI {...uiProps} />
      <DownloadModal {...modalProps} />
    </>
  )
}

Nav.defaultProps = {
  share: ''
}

Nav.propTypes = {
  title: PropTypes.string,
  share: PropTypes.string,
  // doShare: PropTypes.func.isRequired,
  doLanguageSelect: PropTypes.func.isRequired
}

export default Nav
