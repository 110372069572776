const doReview = (props) => {
  const { historyData, uuid } = props
  let reviewInitState = {}
  if (historyData != null) {
    const {
      selected,
      orientation,
      question,
      currentSpread,
      timestamp
    } = historyData

    reviewInitState = {
      selected,
      question,
      orientation,
      shuffled: 'done',
      locked: true,
      currentSpread,
      allSelected: true,
      currentSpreadHash: timestamp,
      currentSpreadUUID: uuid
    }
  }
  return reviewInitState
}

export default doReview
