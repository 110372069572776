
import React from 'react'

// eslint-disable-next-line react/prefer-stateless-function
class WalkthroughStart extends React.Component {
  render () {
    const {
      locked,
      currentStage,
      handleWalkthrough,
      doEnd
    } = this.props

    if (!locked) {
      return ''
    }

    return (
      <div className={`walkthrough ${currentStage}`}>
        <button

          className='waves-effect waves-light btn btn-small btn-prime'
          onClick={handleWalkthrough[currentStage].onClick}
        >
          {handleWalkthrough[currentStage].button}
        </button>
        {(currentStage === 'start') && (
          <div>
            <br />
            <br />
            OR
            <br />
            <br />
            <button

              className='waves-effect waves-light btn btn-small btn-second'
              onClick={doEnd}
            >
              Skip Reading
            </button>
          </div>)}
      </div>
    )
  }
}

export default WalkthroughStart
