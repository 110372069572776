import React, { Component } from 'react'
import { Router } from '@reach/router'
// import Loadable from 'react-loadable';

import Privacy from 'pages/Privacy'
import Home from '../../pages/Home'
import History from '../../pages/History'
import Spread from '../../pages/Spread'
import ComingSoon from '../../pages/ComingSoon'
import Review from '../../pages/History/Review'
import Matrix from '../../components/Matrix'
import Underway from '../../components/Underway'
import Account from '../../pages/Auth/Account'
// import Cards from 'components/Cards';

// const AsyncHome = Loadable({
//   loader: () => import('pages/Home'),
//   loading: <div>Loading...</div>,
//   delay: 500, // 0.5 seconds
// });

// const AsyncPrivacy = Loadable({
//   loader: () => import('pages/privacy'),
//   loading: <div>Loading...</div>,
//   delay: 500, // 0.5 seconds
// });

class Main extends Component { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props)

    this.state = {}
  }

  render () {
    const { Cards } = this.props
    return (
      <div>
        <Router>
          <Home path='/' locale='zh' />
          <Spread path='/share' locale='zh' Cards={Cards} />
          <Spread path='/spread/:spreadID' />
          <History path='/history' />
          <History path='/history/:year' />
          <Review path='/history/:yymmdd/:uuid' />
          <Privacy path='/privacy'>
            <Privacy path='opt-out' />
          </Privacy>
          <ComingSoon path='/soon' />
          {window.location.href.indexOf('localhost') < 0
            ? (<Underway path='/account' />)
            : (<Account path='/account' />)}
          <Matrix path='/matrix' />
        </Router>
      </div>
    )
  }
}

export default Main
