import React from 'react'

const UserInfo = () => {
  return (
    <>
      WIP
    </>
  )
}

export default UserInfo
