import React, { useEffect, useState } from 'react'
import selectText from 'helpers/selectText'

import './share.css'

import IconButton from '@material-ui/core/IconButton'
import ShareIcon from '@material-ui/icons/Share'
import Modal from '@material-ui/core/Modal'

import getShareURL from 'lib/getShareURL'

const Share = (props) => {
  const { data: { currentSpread, selected, orientation } } = props
  const [state, setState] = useState({
    url: '',
    copyText: 'Copy',
    show: false
  })

  useEffect(() => {
    const { show } = state
    if (show) {
      const shareUrl = currentSpread && selected
        ? getShareURL({ currentSpread, selected, orientation })
        : ''
      setState((prev) => {
        if (prev.url === shareUrl) {
          return prev
        }
        return {
          url: shareUrl,
          copyText: 'Copy',
          show
        }
      })
    }
  }, [state, currentSpread, selected, orientation])

  function doCopy (e) {
    selectText(e)
    setState({
      copyText: 'Copied',
      show: true
    })
  }

  function handleOpen () {
    setState({
      copyText: 'Copy',
      show: true
    })
  }

  function handleClose () {
    setState((prev) => {
      return {
        ...prev,
        copyText: 'Copy',
        show: false
      }
    })
  }

  function doCopyText (text) {
    document.querySelector('.tooltipped-share').focus()
    document.querySelector('.tooltipped-share').select()
    navigator.clipboard.writeText(text).then(() => {
      setState({
        copyText: 'Copied',
        show: true
      })
    }, (err) => {
      console.error(err)
    })
  }

  const { copyText, show, url } = state

  // if (url && url.) {
  //   return ''
  // }

  return (
    <div className='share-wrapper'>
      <IconButton id='share-button' aria-label='share' onClick={handleOpen}>
        <ShareIcon />
      </IconButton>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <div className='share'>
          <h3>Share this page</h3>
          <textarea
            className='tooltipped-share'
            width='200'
            onClick={e => doCopy(e)}
            data-position='top'
            data-tooltip='Copied'
            readOnly
            value={url}
          />
          <div className='btn-bullet'>
            <button

              className='btn waves-effect waves-light btn-second'
              onClick={() => doCopyText(url)}
              onTouchEnd={() => doCopyText(url)}
            >
              {copyText}
            </button>
            <button

              className='btn waves-effect waves-light btn-prime'
              onClick={handleClose}
              onTouchEnd={handleClose}
            >
              Done
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Share
