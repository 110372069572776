import React from 'react'
import { navigate } from '@reach/router'

import TarotCard from 'components/History/TarotCard'
import TarotText from 'components/History/TarotText'
import sortSelected from 'reducers/sortSelected'
import CardsInitState from 'components/Cards/CardsInitState'
import { months } from 'data/config'

import cards from 'data/zh/cards.json'
class HistoryCard extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props)

    this.state = {}

    this.renderSelectedText = this.renderSelectedText.bind(this)
    this.resetSaveData = this.resetSaveData.bind(this)
    this.doAgain = this.doAgain.bind(this)
  }

  resetSaveData (mode = 'review') {
    const { question, time } = this.props
    if (CardsInitState !== null) {
      const newSaveData = {
        ...CardsInitState,
        question: {
          text: question,
          length: question.length
        },
        mode,
        currentSpreadHash: time
      }
      sessionStorage.setItem('saveData', JSON.stringify(newSaveData))
    }
  }

  doAgain () {
    const { spreadId } = this.props
    const spreadURL = `/spread/${spreadId}`
    this.resetSaveData('again')
    navigate(spreadURL)
  }

  doReview () {
    const { date, historyData } = this.props
    const { uuid } = historyData
    const reviewURL = `/history/${date}/${uuid}`
    this.resetSaveData('review')
    navigate(reviewURL)
  }

  renderSelectedText (selected, orientation) {
    const { positions } = this.props
    const selectedSorted = sortSelected(selected, positions)
    const selectedText = selectedSorted.map((cardID, cardIndex) => (
      <TarotText
        key={`${cardID}_${cardIndex * 10}`}
        cardID={cardID}
        cardIndex={cardIndex}
        orientation={orientation[cardIndex]}
        cardName={cards[cardID].name}
        cardPosition={positions[cardIndex].title}
      />
    ))
    return selectedText
  }

  // eslint-disable-next-line class-methods-use-this
  renderSelectedCards (selected, orientation) {
    const { images } = this.props

    const selectedCards = selected.map((cardID, cardIndex) => (
      <TarotCard
        key={`${cardID}_${cardIndex * 10}`}
        cardID={cardID}
        cardIndex={cardIndex}
        src={images[cardID]}
        orientation={orientation[cardIndex]}
      />
    ))

    return selectedCards
  }

  render () {
    const deck = localStorage.getItem('deck') || 'cards'

    const {
      time,
      historyData,
      index,
      name,
      spreadId,
      date,
      question,
      isReviewMode,
      updateIndicator,
      currentID
    } = this.props

    const {
      selected: selectedFromData,
      orientation,
      uuid
    } = historyData

    const selected = selectedFromData && selectedFromData.length > 0
      ? selectedFromData.map((item) => item.replace('_', ''))
      : selectedFromData

    const thisDate = new Date(date)
    const m = 11 - thisDate.getMonth()
    // const y = thisDate.getFullYear()
    const dd = thisDate.getDate() < 10 ? `0${thisDate.getDate()}` : thisDate.getDate()
    const mmm = months[m]
    // const dateFormatted = `${mmm} ${dd}, ${y}`
    const singleRow = selected.length > 8 ? '' : 'single-row'
    const activeClass = uuid === currentID ? 'active' : ''
    const spreadBG = `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/app%2F${spreadId}.jpg?alt=media`
    return (
      <div
        key={`${time}_${index * 10}`}
        className={`item ${activeClass}`}
        id={uuid}
        onMouseEnter={() => updateIndicator(date, uuid)}
        onTouchStart={() => updateIndicator(date, uuid)}
      >
        <div
          className='item-bg'
          style={{ backgroundImage: `url(${spreadBG})` }}
        />
        <div className='surface'>
          <div className='row'>
            <div className='number col s6 m6 l6'>
              <span className='font-serif'>
                {mmm}
                &nbsp;
                {dd}
              </span>
              {name}
            </div>
            <div className='time  col s6 m6 l6'>
              {/* TODO: add fav */}
            </div>
          </div>
          <h3 className='history-name'>{question}</h3>
          {/* <div className="spread">
            {dateFormatted}
          </div> */}
          <div className={`results ${singleRow} vertical-lr`}>
            {this.renderSelectedText(selected, orientation)}
            <div className='clearfix' />
          </div>
          {!isReviewMode && (
            <div className='view'>
              <div className='btn-bullet'>
                <button

                  className='btn btn-second'
                  onClick={() => this.doAgain()}
                  onKeyPress={() => this.doAgain()}
                >
                  Again
                </button>
                <button

                  className='btn btn-prime'
                  onClick={() => this.doReview()}
                  onKeyPress={() => this.doReview()}
                >
                  Review
                </button>
              </div>
            </div>
          )}
        </div>
        <div
          className={`background spread-container ${deck} spread-${spreadId}`}
        >
          {this.renderSelectedCards(selected, orientation)}
        </div>
      </div>
    )
  }
}

export default HistoryCard
