import React from 'react'

import './index.css'

class TarotText extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props)

    this.state = {}
  }

  render () {
    const {
      cardIndex,
      cardName,
      orientation,
      cardPosition,
      showPosition
    } = this.props

    const positionTitle = cardPosition

    const title = showPosition ? positionTitle : cardName
    const parts = title.split(' ')
    const titleMarkup = []
    parts.forEach((part, i) => {
      if (i < 1) {
        titleMarkup.push(<span key={`title_${i}`}>{part}</span>)
      } else {
        titleMarkup.push(
          <span key={`title_${i}`} className='upright'>
            {' '}
            {part}
            {' '}
          </span>
        )
      }
    })

    return (
      <div
        className='tarot-text'
        data-index={cardIndex + 1}
      >
        <span className='upright'>
          {cardIndex + 1}
        </span>
        {titleMarkup}
        {!showPosition && (<span className='sp-line'> &mdash; </span>)}
        {!showPosition && (<span>{(orientation === 'up') ? '正位' : '逆位'}</span>)}
      </div>
    )
  }
}

export default TarotText
