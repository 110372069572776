import React from 'react'

// import './spread-info.css';

class SpreadInfo extends React.Component {
  // constructor (props) {
  //   super(props)
  // }

  render () {
    const {
      spread,
      doSpreadInfo
    } = this.props

    let spreadInfoData = []
    if (spread.info !== undefined) {
      spreadInfoData = spread.info.split('\n')
        .map((item, index) => <p key={`p-${index * 10}`}>{item}</p>)
    }

    return (
      <div
        className='spread-info absolute-with-scroll'
        onClick={doSpreadInfo}
        onKeyDown={doSpreadInfo}
        role='button'
        tabIndex='0'
        aria-pressed='false'
      >
        <h1>{spread.name}</h1>
        {spreadInfoData}
      </div>
    )
  }
}

export default SpreadInfo
