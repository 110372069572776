// Use the browser's built-in functionality to quickly and safely escape
// the string
const escapeHTML = (str) => {
  const regex = /[<>&]+/gm
  const newStr = str.replace(regex, '')
  const div = document.createElement('div')
  div.appendChild(document.createTextNode(newStr))
  return div.innerHTML
}

export default escapeHTML
