import React, { useContext } from 'react'
import { navigate } from '@reach/router'
import { DB } from 'context/DB'

// eslint-disable-next-line react/prefer-stateless-function
const Controls = (props) => {
  const db = useContext(DB)
  const {
    uuid,
    year,
    doSpreadInfo,
    doReset,
    doSaveResult,
    doPrint,
    spreadInfoData
  } = props
  let { mode } = props
  mode = mode === undefined ? 'default' : mode

  const handleBack = {
    default: {
      text: 'Back to Choices',
      onClick: doReset
    },
    review: {
      text: 'Back to History',
      onClick: () => {
        navigate(`/history/${year}#${uuid}`)
      }
    },
    again: {
      text: 'Back to History',
      onClick: () => {
        navigate(`/history/${year}#${uuid}`)
      }
    }
  }

  async function saveToDB () {
    if (!db) {
      return
    }
    const historyResult = doSaveResult()
    const {
      uuid,
      timestamp
    } = historyResult

    const date = new Date(timestamp * 1000).toISOString()
    const tx = db.transaction('history', 'readwrite')
    const store = tx.objectStore('history')

    await store.put({
      date,
      uuid,
      starred: false,
      timestamp: historyResult.timestamp,
      spreadId: historyResult.currentSpread.id,
      question: historyResult.question,
      selected: historyResult.selected,
      orientation: historyResult.orientation
    }, uuid).catch((err) => {
      throw err
    })
  }

  return (
    <div className='controls-hover'>
      <button
        className='btn-reset waves-effect waves-light btn btn-second hide-on-small-only btn-small'
        onClick={() => doPrint()}
      >
        Print
      </button>
      {mode !== 'review' &&
        (
          <button
            className='btn-reset waves-effect waves-light btn btn-second btn-small'
            onClick={saveToDB}
          >
            Save
          </button>
        )}
      {(spreadInfoData.length > 0) &&
        (
          <button
            className='btn-reset waves-effect waves-light btn btn-second btn-small'
            onClick={doSpreadInfo}
          >
            Info
          </button>
        )}
      <button

        className='btn-reset waves-effect waves-light btn btn-prime btn-small'
        onClick={handleBack[mode].onClick}
      >
        {handleBack[mode].text}
      </button>
    </div>
  )
}

export default Controls
