import React from 'react'
// import { Link } from '@reach/router';

import CookiesNotice from 'components/CookiesNotice'
import './footer.css'

class Footer extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props)

    this.state = {
    }
  }

  render () {
    return (
      <div className='footer'>
        {/* <Consent siteTitle={this.props.siteTitle} /> */}
        <CookiesNotice />
        {/* <div className="copyright">
          {(new Date()).getFullYear()} &copy; <Link to="/" href="/">{this.props.siteTitle}</Link>
         &nbsp;&nbsp; . &nbsp;&nbsp; <Link to="/privacy" href="/privacy">Privacy</Link>
        </div> */}
      </div>
    )
  }
}

export default Footer
