const doNext = (state) => {
  const thisIndex = state.hoveredIndex
  let nextIndex = thisIndex + 1
  const nextIndexTitle = state.spread.positions[nextIndex].title

  if (nextIndexTitle === null) {
    nextIndex += 1
  }

  const nextIndexInSelected = (state.spread.positions[nextIndex].index) - 1 || 0
  const nextID = state.selected[nextIndexInSelected]
  const newView = state.viewed.slice()
  const prevHover = state.hovered

  if (nextIndexInSelected === (state.selected.length - 1)) {
    return {
      walkthrough: 'end',
      hovered: nextID,
      hoveredIndex: nextIndex
    }
  }

  if (newView.indexOf(nextID) < 0) {
    newView.push(nextID)
  }

  if (prevHover === nextID) {
    return {
      hovered: null
    }
  }
  return {
    hovered: nextID,
    viewed: newView,
    hoveredIndex: nextIndex
  }
}

export default doNext
