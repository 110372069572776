const log = {
  info: (...data) => {
    const path = window.location.pathname
    console.info(`info: ${path}`, data)
  },
  debug: (...data) => {
    const path = window.location.pathname
    if (window.location.href.indexOf('localhost') >= 0) {
      console.info(`debug: ${path}`, data)
    }
  },
  error: (...data) => {
    const path = window.location.pathname
    console.error(`error: ${path}`, data)
  }
}

export default log
