
import React from 'react'

import { imageBase } from 'data/config'

// eslint-disable-next-line react/prefer-stateless-function
class BoardBackground extends React.Component {
  render () {
    const { currentSpreadID } = this.props

    return (
      <img
        className='board-bg'
        src={`${imageBase}/app%2F${currentSpreadID}.jpg?alt=media`}
        alt=''
        onError={(e) => { e.target.src = '/cards/empty.png' }}
      />
    )
  }
}

export default BoardBackground
