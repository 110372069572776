import getUrlParameter from 'helpers/getUrlParameter'

const getShare = () => {
  const spreadGiven = getUrlParameter('spread')
  let selectedGiven = getUrlParameter('selected')
  let oGiven = getUrlParameter('o')
  let stateGiven = {}
  // const spread = {};

  if (spreadGiven.length <= 0 || selectedGiven.length <= 0 || oGiven.length <= 0) {
    return {}
  }

  selectedGiven = selectedGiven.split(',')
  oGiven = oGiven.match(/.{1}/g)
  oGiven = oGiven.map((o) => {
    const thisO = o.replace('0', 'rev').replace('1', 'up')
    return thisO
  })

  if (spreadGiven.length > 0 && selectedGiven.length > 0) {
    stateGiven = {
      currentSpread: {
        id: spreadGiven
      },
      shuffled: 'done',
      selected: selectedGiven,
      viewed: selectedGiven,
      allSelected: true,
      orientation: oGiven,
      share: '',
      walkthrough: 'end'
    }
  }
  return stateGiven
}

export default getShare
