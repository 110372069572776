const getTranslations = async (locale, SpreadIDGiven) => {
  let spreadID = SpreadIDGiven
  if (SpreadIDGiven === null || SpreadIDGiven === '') {
    spreadID = 'celtic-cross'
  }
  let spread = null
  let cards = null

  if (Object.prototype.toString.call(spreadID) === '[object String]') {
    spread = await import(`data/${locale}/${spreadID}.json`)
    cards = await import(`data/${locale}/cards.json`)
  }

  return {
    spread,
    cards
  }
}

export default getTranslations
