import React from 'react'
// import PropTypes from 'prop-types'
import { Link } from '@reach/router'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'

import LockIcon from '@material-ui/icons/Lock'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MoreVert from '@material-ui/icons/MoreVert'
import CloudDownload from '@material-ui/icons/CloudDownload'
import StyleIcon from '@material-ui/icons/Style'
import BookmarksIcon from '@material-ui/icons/Bookmarks'
// import DeleteIcon from '@material-ui/icons/Delete'
// import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import PhonelinkLockIcon from '@material-ui/icons/PhonelinkLock'

import './nav.css'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 300,
    minHeight: '46px !important',
    top: 0,
    width: '100vw'
  },
  menu: {
    top: '25px !important',
    '& ul': {
      padding: 0,
      borderRadius: '0 !important',
      background: theme.palette.primary.light
    },
    '& ul li': {
      padding: '8px 14px',
      fontSize: '14px'
    },
    '& li svg': {
      marginRight: '0.5rem'
    }
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  toolbar: {
    minHeight: '46px !important'
  },
  badge: {
    '& span': {
      padding: 0,
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.secondary.dark
    }
  },
  title: {
    flexGrow: 1,
    '& a': {
      textDecoration: 'none',
      color: 'inherit'
    }
  }
}))

const NavUI = (props) => {
  const {
    // title,
    // isLoggedIn,
    newDownloads,
    accountAnchor,
    moreAnchor,
    handleMore,
    closeMore,
    handleClick,
    handleClose,
    handleModal,
    navigate
  } = props

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppBar position='fixed'>
        <Toolbar className={classes.toolbar} variant='dense' disableGutters>
          <Link className='logo' to='/' href='/'>
            <img
              src='/project-c.png'
              width='27px'
              height='27px'
              alt='project-c'
              onError={(e) => { e.target.src = '/cards/empty.png' }}
            />
          </Link>

          <Typography variant='h6' className={classes.title}>
            <Link className='logo' to='/' href='/'>
              HaCker
              <Hidden smDown>
                : 駭客塔羅
              </Hidden>
            </Link>
          </Typography>

          <Box justifyContent='flex-end'>
            <IconButton
              size='small'
              className={classes.menuButton} color='inherit' aria-label='Download'
              onClick={() => handleModal('download', true)}
              onKeyPress={() => handleModal('download', true)}
            >
              <Badge badgeContent={newDownloads} color='secondary' className={classes.badge}>
                <CloudDownload />
              </Badge>
            </IconButton>
            <IconButton
              size='small'
              className={classes.menuButton}
              color='inherit'
              aria-label='My account'
              onClick={handleClick}
              onKeyPress={handleClick}
            >
              <AccountCircle />
            </IconButton>
            <IconButton
              size='small'
              className={classes.menuButton}
              color='inherit'
              aria-label='History'
              onClick={() => navigate('/history')}
              onKeyPress={() => navigate('/history')}
            >
              <BookmarksIcon />
            </IconButton>
            <IconButton
              size='small'
              edge='start' className={classes.menuButton} color='inherit' aria-label='More'
              onClick={handleMore}
              onKeyPress={handleMore}
            >
              <MoreVert />
            </IconButton>
          </Box>
          <Menu
            id='account-menu'
            className={classes.menu}
            anchorEl={accountAnchor}
            keepMounted
            open={Boolean(accountAnchor)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}><PhonelinkLockIcon /> Coming soon</MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to='/privacy'>
                <LockIcon />Privacy policy
              </Link>
            </MenuItem>
          </Menu>
          <Menu
            id='more-menu'
            className={classes.menu}
            anchorEl={moreAnchor}
            keepMounted
            open={Boolean(moreAnchor)}
            onClose={closeMore}
          >
            {/* <MenuItem onClick={closeMore}><DeleteIcon /> Erase Everything</MenuItem> */}
            <MenuItem onClick={closeMore}><StyleIcon /> Coming soon</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default NavUI
