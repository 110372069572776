const sortSelected = (selected, spreadPosition) => {
  const selectedSorted = []
  spreadPosition.forEach((position) => {
    const { index } = position
    const selectedIndex = index - 1
    if (selected[selectedIndex] !== undefined) {
      selectedSorted.push(selected[selectedIndex])
    }
  })
  return selectedSorted
}

export default sortSelected
