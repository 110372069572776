import React from 'react'

// eslint-disable-next-line react/prefer-stateless-function
class Walkthrough extends React.Component {
  // constructor (props) {
  //   super(props)
  // }

  render () {
    const {
      stackStatus,
      qText,
      shuffleStatus,
      selectStatus,
      spread,
      selected,
      handleQuestion,
      skipToResults,
      enableShuffle,
      doReady
    } = this.props

    return (
      <div className={`controls row ${stackStatus}`}>
        <div className='col s12 m12'>
          <div className='controls-shuffle'>
            <input
              className='question'
              value={qText}
              onChange={handleQuestion}
              placeholder="What's in your mind?"
              maxLength='255'
            />
            <button

              className='btn-shuffle waves-effect waves-light btn btn-prime btn-small'
              onClick={enableShuffle}
              {...shuffleStatus}
            >
              Begin
            </button>
            <div className='skip'>
              <br />
              OR
              <br />
              <br />
              <button

                className='waves-effect waves-light btn btn-second btn-small'
                onClick={skipToResults}
              >
                Skip to Results
              </button>
            </div>
          </div>
          <div className='controls-ready'>
            Now think about the question in your head and begin shuffling
            the 78 tarot cards.
            <br />
            <br />
            <button

              className='waves-effect waves-light btn btn-small'
              onClick={doReady}
              {...selectStatus}
            >
              Next
            </button>
          </div>
          <div className='controls-spread'>
            You have chosen
            {spread.name}
            .
            <br />
            Go ahead and pick
            {spread.max}
            cards.
            <br />
            <br />
            <div className='cards-selected'>
              Cards Selected:
              {selected.length}
              /
              {spread.max}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Walkthrough
