/* eslint-disable */
!(function (e, t) { typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = t() : typeof define === 'function' && define.amd ? define(t) : (e.ReactIntlLocaleData = e.ReactIntlLocaleData || {}, e.ReactIntlLocaleData.ja = t()); }(this, () => {
  'use strict';

  return [{
    locale: 'ja',
    pluralRuleFunction(e, t) { return 'other'; },
    fields: {
      year: { displayName: '年', relative: { 0: '今年', 1: '翌年', '-1': '昨年' }, relativeTime: { future: { other: '{0} 年後' }, past: { other: '{0} 年前' } } },
      month: { displayName: '月', relative: { 0: '今月', 1: '翌月', '-1': '先月' }, relativeTime: { future: { other: '{0} か月後' }, past: { other: '{0} か月前' } } },
      day: {
        displayName: '日',
        relative: {
          0: '今日', 1: '明日', 2: '明後日', '-2': '一昨日', '-1': '昨日',
        },
        relativeTime: { future: { other: '{0} 日後' }, past: { other: '{0} 日前' } },
      },
      hour: { displayName: '時', relative: { 0: '1 時間以内' }, relativeTime: { future: { other: '{0} 時間後' }, past: { other: '{0} 時間前' } } },
      minute: { displayName: '分', relative: { 0: '1 分以内' }, relativeTime: { future: { other: '{0} 分後' }, past: { other: '{0} 分前' } } },
      second: { displayName: '秒', relative: { 0: '今' }, relativeTime: { future: { other: '{0} 秒後' }, past: { other: '{0} 秒前' } } },
    },
  }];
}));
