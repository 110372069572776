
import React from 'react'

import './index.css'

const ImagesWithStatusText = (props) => {
  const { images, handleImageLoaded, handleImageErrored } = props
  const imagesMarkup = []

  if (images.length > 0) {
    images.forEach((url, index) => {
      imagesMarkup.push(
        <img
          key={`image-${index * 10}`}
          className=''
          src={url}
          onLoad={() => handleImageLoaded(url)}
          onError={() => handleImageErrored(url)}
          alt='download'
        />
      )
    })
  }
  return (
    <div className='images-download'>
      {imagesMarkup}
    </div>
  )
}

export default ImagesWithStatusText
