const getPositionMap = (cardTypes) => {
  const positionMap = []
  let totalCards = 0
  if (cardTypes.indexOf('major') >= 0) {
    totalCards += 22
  }
  if (cardTypes.indexOf('minor') >= 0) {
    totalCards += 56
  }
  console.log(totalCards)
  // const windowWidth = window.innerWidth;
  for (let i = 0; i < totalCards; i += 1) {
    const startDeg = 17.5
    const totalDeg = 35
    const startPos = -36
    const totalWidth = 80
    // const totalPos = 10.5;
    const perDeg = totalDeg / totalCards
    // const perPos = totalPos / totalCards;
    positionMap.push({
      deg: startDeg - (perDeg * i),
      posX: startPos + (totalWidth / totalCards) * i,
      posY: 0
      // posY: startPos + (perPos * i) / 24,
      // deg: startDeg + (perDeg * i),
      // pos: startPos - (perPos * i),
    })
  }
  return positionMap
}

export default getPositionMap
