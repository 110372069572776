import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'

import Nav from 'components/Nav'
import Main from 'router/Main'
import Footer from 'components/Footer'
// import OAuth from 'pages/Base/OAuth';
// import GA from 'components/GA';

import save from 'reducers/doSave'
import hasCorrectCode from 'helpers/hasCorrectCode'
import { toggleFullScreen } from 'helpers/toggleFullScreen'
// import initializeApp from 'firebase/init';
import CardsInitState from 'components/Cards/CardsInitState'
import ComingSoon from 'pages/ComingSoon'
import initState from './initState'
import migrateDB from 'lib/migrateDB'
import log from 'lib/log'

import { DB } from 'context/DB'

// const loadedState = initState;

const Sentry = window.Sentry

const Base = (props) => {
  const db = useContext(DB)
  const { locale: localeProp, hasCode: hasCodeProp = false, initialized: initializedProp = false } = props
  const [validated, setValidate] = React.useState(false)
  const [state, setState] = React.useState({
    ...initState,
    hasCode: hasCodeProp,
    initialized: initializedProp,
    action: '',
    locale: localeProp || 'zh',
    fullScreen: false
  })

  useEffect(() => {
    const { initialized } = state
    const hasCode = hasCorrectCode()

    if (initialized && validated === true && hasCode) {
      setValidate('done')
      setState((prev) => {
        return {
          ...prev,
          hasCode
        }
      })
    }

    console.log('base rendered', validated, initialized, db)
    if (!initialized && db) {
      const hasCode = hasCorrectCode()

      if (window.Sentry) {
        Sentry.init({
          dsn: 'https://cbb3e2931eaf452da2c6988c056bdb45@o168536.ingest.sentry.io/1243658',
          release: 'project-c@latest',
          integrations: [new Sentry.Integrations.BrowserTracing()],

          // We recommend adjusting this value in production, or using tracesSampler
          // for finer control
          tracesSampleRate: 1.0
        })
      }

      const doNotTrack = navigator.length > 0
        ? navigator.doNotTrack
        : null
      setState((prev) => {
        return {
          ...prev,
          hasCode,
          initialized: true,
          doNotTrack, // eslint-disable-line
        }
      })

      migrateDB(db).then(() => {
        log.debug('done migration')
      })
    }
  }, [db, state, validated])

  function doSave () {
    const { Cards } = state
    save('saveData', Cards)
  }

  function doReset () {
    sessionStorage.removeItem('saveData')
    const Cards = CardsInitState
    sessionStorage.setItem('saveData', JSON.stringify(Cards))
    setState({
      Cards: {
        ...CardsInitState,
        action: 'DO_RESET'
      }
    })
  }

  function doDeleteCookies () {
    localStorage.removeItem('code')
    sessionStorage.removeItem('saveData')
    setState({
      // cookies: null,
    })
    navigate('/soon')
  }

  // function doShare () {
  //   const { share } = state
  //   const saveData = JSON.parse(sessionStorage.getItem('saveData'))
  //   const newShare = makeSHare(share, saveData)

  //   setState({
  //     share: newShare
  //   })
  // }

  function doLanguageSelect() { // eslint-disable-line
    // something
  }

  function doFullScreen () {
    const { fullScreen } = state
    if (!fullScreen) {
      toggleFullScreen()
      setState({
        fullScreen: true
      })
    }
  }

  const baseActions = {
    doReset: doReset,
    doSave: doSave,
    doDeleteCookies: doDeleteCookies,
    // doShare: doShare,
    doLanguageSelect: doLanguageSelect
  }

  const {
    locale,
    Page,
    share,
    ready,
    Cards,
    actions,
    hasCode,
    initialized
  } = state

  if (!initialized) {
    return <p>...</p>
  }

  if (initialized && hasCode) {
    return (
      <div
        role='button'
        tabIndex='0'
        className={`${localeProp} ${locale} dark`}
        onClick={() => doFullScreen()}
        onKeyDown={() => doFullScreen()}
      >
        <Nav {...Page} hasCode={hasCode} share={share} {...baseActions} />
        <div className='main' data-ready={ready}>
          <Main Cards={Cards} {...actions} />
        </div>
        <Footer {...Page} />
      </div>
    )
  }

  return <ComingSoon validated={validated} setValidate={setValidate} />
}

Base.propTypes = {
  locale: PropTypes.string.isRequired
}

export default Base
