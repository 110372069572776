import React, { useEffect, useState } from 'react'
import { navigate } from '@reach/router'
// import Loadable from 'react-loadable';

// import getUrlParameter from 'helpers/getUrlParameter'
import getTranslations from 'helpers/getTranslations'
import HomeUI from './ui'

// let scrollTimer

function jumpToIndicator (state) {
  const { indicator } = state
  if (indicator !== undefined) {
    const el = document.getElementById(indicator)
    if (el !== null && el !== undefined) {
      el.scrollIntoView({
        behavior: 'instant',
        block: 'start'
      })
    }
  }
}

const Home = (props) => {
  const { spreadID } = props
  const [state, setState] = useState()
  const [tabValue, setTab] = React.useState(0)

  const handleTab = (event) => {
    const newId = event.target.id.replace('tarot-tab-', '')
    if (newId) {
      setTab(parseInt(newId, 10))
    }
  }

  useEffect(() => {
    if (!state) {
      const homeData = JSON.parse(localStorage.getItem('homeData'))
      let selectedTabInit = ''
      let indicatorInit = ''

      if (homeData !== null) {
        const {
          selectedTab,
          indicator
        } = homeData

        selectedTabInit = selectedTab === undefined ? '' : selectedTab
        indicatorInit = indicator === undefined ? '' : indicator
      }
      let currentSpread = {
        id: null
      }
      let selected = false

      if (spreadID !== null && spreadID !== undefined) {
        currentSpread = {
          id: spreadID
        }
        selected = true
      }

      setState({
        currentSpread,
        selected,
        showSpreadInfo: false,
        showQuestion: false,
        scrollClass: '',
        indicator: indicatorInit,
        selectedTab: selectedTabInit
      })

      // return () => {
      //   // unmount
      //   window.clearTimeout(scrollTimer)
      // }
    }
  }, [state, spreadID])

  useEffect(() => {
    if (state) {
      jumpToIndicator(state)
    }
  }, [state])

  // function redoSelect () {
  //   navigate('/')
  //   setState({
  //     currentSpread: {
  //       id: null
  //     },
  //     selected: false,
  //     showQuestion: false
  //   })
  // }

  function selectSpread (id) {
    // const { currentSpread } = state
    let { locale } = props
    // e.preventDefault()
    // e.persist()
    locale = locale === undefined ? 'zh' : locale
    // const newSpread = currentSpread
    // newSpread.id = id

    getTranslations(locale, id).then((data) => {
      const thisSpread = data.spread
      if (thisSpread !== null) {
        setState({
          currentSpread: {
            ...thisSpread
          },
          selected: true,
          showSpreadInfo: false
        })
        navigate(`/spread/${id}?mode=default`)
      }
    })
  }

  function doSpreadInfo (id, e) {
    const { currentSpread, showSpreadInfo } = state
    let { locale } = props
    e.preventDefault()
    e.persist()

    const newSpread = currentSpread
    const showInfo = showSpreadInfo
    newSpread.id = id
    locale = locale === undefined ? 'zh' : locale

    if (!showInfo) {
      getTranslations(locale, id).then((data) => {
        const thisSpread = data.spread
        if (thisSpread !== null) {
          setState({
            currentSpread: {
              ...thisSpread
            },
            selected: false,
            showSpreadInfo: !showSpreadInfo,
            showQuestion: false
          })
        }
      })
    } else {
      setState({
        showSpreadInfo: !showSpreadInfo,
        showQuestion: false
      })
    }
  }

  function updateIndicator (id, e) {
    const { indicator } = state
    if (id !== null && indicator !== id) {
      // setState((prev) => {
      //   return {
      //     ...prev,
      //     indicator: id,
      //     // scrollClass: 'disable-scrolling'
      //   }
      // })
      const el = document.querySelector(`#${id}`)
      el.focus()
      scrollTo(id, e)
      // window.clearTimeout(scrollTimer)
      // scrollTimer = window.setTimeout(() => {
      //   setState((prev) => {
      //     return {
      //       ...prev,
      //       scrollClass: ''
      //     }
      //   })
      // }, 1000)
    }
  }

  function scrollTo(id, e) { // eslint-disable-line
    if (e !== undefined) {
      e.preventDefault()
    }
    const { indicator, selectedTab } = state
    const el = document.querySelector(`#${id}`)

    // Scroll to a certain element
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })

    if (indicator !== id) {
      const homeData = {
        selectedTab,
        indicator: id
      }
      localStorage.setItem('homeData', JSON.stringify(homeData))
    }
  }

  if (!state) {
    return ''
  }

  let selectClass = ''
  let showClass = 'show'
  const {
    selected,
    indicator,
    scrollClass,
    currentSpread,
    showSpreadInfo,
    showQuestion
  } = state

  if (selected) {
    selectClass = 'selected'
    showClass = ''
  }

  const uiProps = {
    showSpreadInfo,
    currentSpread,
    showQuestion,
    scrollClass,
    selectClass,
    showClass,
    tabValue,
    handleTab,
    doSpreadInfo,
    selectSpread,
    scrollTo,
    updateIndicator,
    indicator
  }

  return (
    <HomeUI {...uiProps} />
  )
}

export default Home
