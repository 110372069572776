import getPositionMap from 'helpers/getPositionMap'
import stackIDs from 'data/stack.json'
import getUrlParameter from 'helpers/getUrlParameter'

let cardTypes = getUrlParameter('types')
let deck = getUrlParameter('deck')

if (cardTypes && cardTypes.length > 0) {
  localStorage.setItem('cardTypes', cardTypes)
} else {
  cardTypes = localStorage.getItem('cardTypes')
    ? localStorage.getItem('cardTypes').split(',')
    : ['major', 'minor']
}

if (deck && deck.length > 0) {
  localStorage.setItem('deck', deck)
} else {
  deck = localStorage.getItem('deck')
    ? localStorage.getItem('deck')
    : 'cards'
}

if (deck === 'cards-eva') {
  cardTypes = ['major']
  localStorage.setItem('cardTypes', 'major')
} else {
  cardTypes = ['major', 'minor']
  localStorage.setItem('cardTypes', 'major,minor')
}

const stack = []

if (cardTypes && cardTypes.indexOf('major') >= 0) {
  stackIDs.forEach((card) => {
    if (card.id.indexOf('m') === 0) {
      stack.push(card)
    }
  })
}

if (cardTypes && cardTypes.indexOf('minor') >= 0) {
  stackIDs.forEach((card) => {
    if (card.id.indexOf('m') < 0) {
      stack.push(card)
    }
  })
}

const CardsInitState = {
  windowWidth: window.innerWidth,
  stack,
  currentSpread: { id: '' },
  currentSpreadHash: null,
  currentSpreadUUID: null,
  question: {
    text: '',
    length: 0
  },
  spread: {},
  cardTypes,
  showSpreadInfo: false,
  showQuestion: false,
  shuffled: 'initial',
  hovered: null,
  hoveredIndex: 0,
  viewing: '',
  viewed: [],
  locked: false,
  walkthrough: 'start',
  allSelected: false,
  selected: [],
  selectedKey: null,
  orientation: [],
  positions: [],
  positionMap: getPositionMap(cardTypes),
  mousePositions: { x: 0, y: 0 },
  share: '',
  printing: false
}

export default CardsInitState
