import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

// import images from 'data/images.js';
import SpreadInfo from 'components/SpreadInfo'
import log from 'lib/log'

import spreadsData from 'data/zh/spreads.json'

function TabPanel (props) {
  const { children, value, tabValue, index, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={tabValue !== index}
      id={`tarot-tabpanel-${index}`}
      aria-labelledby={`tarot-tab-${index}`}
      {...other}
    >
      {tabValue === index && children}
    </div>
  )
}

function a11yProps (index) {
  return {
    id: `tarot-tab-${index}`,
    'aria-controls': `tarot-tabpanel-${index}`
  }
}

const HomeUI = (props) => {
  const {
    showSpreadInfo,
    currentSpread,
    showQuestion,
    scrollClass,
    selectClass,
    showClass,
    tabValue,
    handleTab,
    doSpreadInfo,
    selectSpread,
    scrollTo,
    updateIndicator,
    indicator
  } = props

  function renderSpreadList (data, indicator) {
    const spreadListMarkup = []
    const spreadTabMarkup = []
    const thisData = Object.assign(data)

    Object.keys(thisData).forEach((key, tabIndex) => {
      const { name } = thisData[key]
      const { spreads } = thisData[key]
      const thisList = []
      const indicatorMarkup = []

      spreads.forEach((spread, index) => {
        let indicatorClass = ''
        if (indicator === spread.id) {
          indicatorClass = 'active'
        }

        if (index === 0) {
          spreadTabMarkup.push(
            <Tab
              key={`tab-${spread.id}-${tabIndex}`}
              label={name}
              {...a11yProps(tabIndex)}
            />
          )
        }

        indicatorMarkup.push(
          <a
            tabIndex={(index * 3) + 10}
            key={`${key}-${index * 20}`}
            className={`${indicatorClass} circle circle-${spread.id}`}
            href={`#${spread.id}`}
            onClick={e => scrollTo(spread.id, e)}
          >
            <span>{spread.name}</span>
          </a>
        )

        thisList.push(
          <div
            key={`${key}-${index * 10}`}
            id={spread.id}
            className='choice'
            onMouseEnter={e => updateIndicator(spread.id, e)}
            onClick={(e) => { log.debug('clicked', e.target) }}
          >
            <div className='overlay' />
            <img
              className='bg'
              src={`https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/app%2F${spread.id}.jpg?alt=media`}
              alt={spread.name}
              onError={(e) => { e.target.src = '/cards/empty.png' }}
            />
            <div className='content'>
              <h2>
                {spread.name}
              </h2>
              <div className='desc'>
                {spread.desc}
                {spread.info}
              </div>
              <div className='btn-bullet' onClick={(e) => { log.debug('clicked', e.target) }}>
                <button
                  tabIndex={(index * 3) + 11}
                  className='btn waves-effect waves-light btn-second'
                  onClick={e => doSpreadInfo(spread.id, e)}
                  onKeyPress={e => doSpreadInfo(spread.id, e)}
                >
                  {spread.max}
                  {' '}
                  枚
                </button>
                <button
                  tabIndex={(index * 3) + 12}
                  className='btn waves-effect waves-light btn-prime'
                  onClick={e => selectSpread(spread.id, e)}
                  onKeyPress={e => selectSpread(spread.id, e)}
                >
                  Start
                </button>
              </div>
            </div>
          </div>
        )
        // }
      })
      spreadListMarkup.push(
        <div id={key} key={`${key}-${name}`} className='content'>
          <TabPanel value={tabIndex} tabValue={tabValue} index={tabIndex}>
            <>
              <div className='indicators'>
                {indicatorMarkup}
              </div>
              <div>
                {thisList}
              </div>
            </>
          </TabPanel>
        </div>
      )
    })

    return {
      spreadTabMarkup,
      spreadListMarkup
    }
  }

  const listMarkup = renderSpreadList(spreadsData, indicator)
  const {
    spreadTabMarkup,
    spreadListMarkup
  } = listMarkup

  return (
    <div className='page-spread'>
      {(showSpreadInfo &&
        (
          <SpreadInfo
            spread={currentSpread}
            doSpreadInfo={e => doSpreadInfo(currentSpread.id, e)}
            selectSpread={e => selectSpread(currentSpread.id, e)}
            showQuestion={showQuestion}
          />
        )
      )}
      <div className={`spread-select absolute-with-scroll ${scrollClass} ${selectClass} ${showClass}`}>
        <div className='content'>
          <h1>Make a choice</h1>
          <br />
          <div className='choices'>
            <Tabs
              value={tabValue}
              onChange={handleTab}
              variant='standard'
              centered
              selectionFollowsFocus
              indicatorColor='secondary'
              textColor='secondary'
              aria-label='tarot-tab'
            >
              {spreadTabMarkup}
            </Tabs>
            {spreadListMarkup}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeUI
