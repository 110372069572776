import business from 'data/zh/business-partnership.json'
import career from 'data/zh/career.json'
import celtic from 'data/zh/celtic-cross.json'
import cupid from 'data/zh/cupid.json'
import diamond from 'data/zh/diamond.json'
import cup from 'data/zh/loving-cup.json'
import mirror from 'data/zh/mirror.json'
import partnership from 'data/zh/partnership.json'
import sphinx from 'data/zh/sphinx.json'
import star from 'data/zh/star.json'
import three from 'data/zh/three-choices.json'
import two from 'data/zh/two-choices.json'
import zodiac from 'data/zh/zodiac.json'

const spreads = {
  'business-partnership': business,
  'celtic-cross': celtic,
  career,
  cupid,
  diamond,
  'loving-cup': cup,
  mirror,
  partnership,
  sphinx,
  star,
  'three-choices': three,
  'two-choices': two,
  zodiac
}

export default spreads
