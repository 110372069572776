import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import app from 'firebase/app'
import { openDB } from 'idb/with-async-ittr.js'

import firebaseConfig from '../../config/firebase.json'
import googleConfig from '../../config/google.json'
import log from 'lib/log'

const promisify = (fn) => {
  return (...args) => {
    return new Promise((resolve, reject) => {
      function customCallback (err, ...results) {
        if (err) {
          return reject(err)
        }
        return resolve(results.length === 1 ? results[0] : results)
      }
      args.push(customCallback)
      fn.call(this, ...args)
    })
  }
}

async function initialize () {
  await new Promise(resolve => setTimeout(resolve, 500))
  await app.initializeApp(firebaseConfig).firestore()
  app.auth().setPersistence(app.auth.Auth.Persistence.LOCAL)
  const db = app.firestore()
  const firebase = {
    auth: app.auth,
    db
  }
  log.debug('firebase initialized')

  const { gapi } = window

  if (gapi) {
    const load = promisify(gapi.load)
    await load('client:auth2')
    await gapi.client.init({
      apiKey: googleConfig.apiKey,
      discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
      clientId: googleConfig.clientId,
      scope: 'https://www.googleapis.com/auth/drive.appdata https://www.googleapis.com/auth/drive.file'
    })
    log.debug('gapi initialized')
  }

  const indexedDB = await openDB('Project-C', 1, {
    upgrade (indexedDB) {
      // Create a store of objects
      const config = indexedDB.createObjectStore('config', {
        // The 'id' property of the object will be the key.
        // keyPath: 'id',
        // If it isn't explicitly set, create a value by auto incrementing.
        autoIncrement: false
      })
      const history = indexedDB.createObjectStore('history', {
        // The 'id' property of the object will be the key.
        // keyPath: 'id',
        // If it isn't explicitly set, create a value by auto incrementing.
        autoIncrement: false
      })
      // Create an index on the 'date' property of the objects.

      config.createIndex('id', 'id', { unique: true })
      config.createIndex('date', 'date')

      history.createIndex('id', 'id', { unique: true })
      history.createIndex('date', 'date')
      history.createIndex('starred', 'starred')
      history.createIndex('spreadId', 'spreadId')
    }
  })

  return {
    firebase,
    gapi,
    db: indexedDB
  }
}

export default initialize
