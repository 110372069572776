import React from 'react'

const deck = localStorage.getItem('deck') || 'cards'

class Card extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props)

    this.state = {}
  }

  render () {
    const {
      id,
      name,
      desc,
      isSelected,
      isViewed,
      isViewing,
      indexViewing,
      hasKeyCard,
      hovered,
      meaning_up, // eslint-disable-line
      meaning_rev, // eslint-disable-line
      order,
      orientation,
      positions,
      selectedNumber,
      shuffled,
      spread,
      spreadPosition,
      type,
      applyHoverAndView,
      handleSelect,
      doHoverPosition,
      value_int, // eslint-disable-line
      images
    } = this.props

    const numbers = ['O', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII', 'XIII', 'XIV', 'XV', 'XVI', 'XVII', 'XIII', 'XIX', 'XX', 'XXI', 'XXII', 'XXIII']
    let translateZ = `${38.5 - (order / 2)}px`
    let thisDeg = 0
    let thisPosX = 0
    let thisPosY = 0
    // let marginLeft = -105;
    let marginTop = 0
    let thisTransform = `rotateX(55deg) translate3d(0,0,${translateZ}) scale(0.7)`
    let cardMarkup = ''
    let selectClass = 'not-selected'
    let hoverClass = ''
    let viewingClass = ''
    let viewClass = ''
    const orientationClass = orientation
    let descriptionMarkup = ''
    let eventType = 'none'
    let positionNumber = selectedNumber
    let numberViewIndex = numbers[indexViewing]
    let numberSelectIndex = numbers[selectedNumber]
    let cardDisable = false
    let spreadTitle = null
    const print = window.location.href.indexOf('print') >= 0
    if (spreadPosition !== undefined) {
      spreadTitle = spreadPosition.title || null
    }

    if (spreadTitle === null) {
      cardDisable = 'card-disabled'
    }

    if (hasKeyCard) {
      numberViewIndex = numbers[indexViewing - 1]
      numberSelectIndex = numbers[selectedNumber - 1]
      positionNumber -= 1
    }

    // if (window.innerWidth <= 640) {
    //   marginLeft = -43;
    // }

    if (isViewed && spreadPosition !== undefined &&
      spreadPosition.title !== null) {
      viewClass = 'viewed'
    }

    if (isViewing) {
      viewingClass = 'viewing'
    }

    if (shuffled === 'shuffling') {
      eventType = 'shuffling'
    }

    if (shuffled === 'ready') {
      eventType = 'ready'
    }

    if (spread.length > 0) {
      eventType = 'spread'
    }

    if (id === hovered || (print)) {
      hoverClass = 'hovered'
    }

    if (spreadPosition !== undefined &&
      desc !== undefined && desc.length > 0) {
      const meaningUp = meaning_up.split('\n').map((item, index) => (
        <p key={`up-${index * 10}`}>
          {item}
          <br />
        </p>
      ))
      const meaningRev = meaning_rev.split('\n').map((item, index) => (
        <p key={`rev-${index * 10}`}>
          {item}
          <br />
        </p>
      ))
      const newDesc = desc.split('\n').map((item, index) => (
        <p key={`desc-${index * 10}`}>
          {item}
          <br />
        </p>
      ))

      descriptionMarkup = (
        <div className='info'>
          <div
            className='des font-sans'
          >
            <div className='spread-position'>
              {numbers[positionNumber]}
              {' '}
              -
              {' '}
              {spreadTitle}
            </div>
            <h2>
              {type === 'major' ? `${numbers[value_int]} ` : ''}
              {name}
            </h2>
            <div className='orientation'>
              {orientationClass !== 'rev' ? '正位' : '逆位'}
            </div>
            {(orientationClass !== 'rev') ? meaningUp : meaningRev}
            <br />
            <b>基本牌義</b>
            {newDesc}
            <br />
            <br />
          </div>
        </div>
      )
    }

    if (shuffled !== 'initial' && shuffled !== 'done') {
      translateZ = '0'
      if (positions === undefined) {
        // console.info(this.props);
      }
      thisDeg = positions.deg
      thisPosX = positions.posX
      thisPosY = positions.posY
      thisTransform = `rotateX(55deg) rotateZ(${thisDeg}deg) translate3d(${thisPosX}vw, ${thisPosY}px, ${translateZ}px) scale(0.7)`
    }

    if (shuffled === 'done') {
      const cardURL = images[id]
      let alt = name
      if (orientation !== 'rev') {
        alt = `${alt} - 正位`
      } else {
        alt = `${alt} - 逆位`
      }

      cardMarkup = (
        <img
          className='front'
          src={cardURL}
          alt={alt}
          onError={(e) => { e.target.src = '/cards/empty.png' }}
        />
      )
    }

    let zIndex = 100 - order

    if (isSelected) {
      selectClass = 'selected'
      zIndex = 100 + selectedNumber
    }
    if (isSelected && spread.length <= 0) {
      marginTop = selectedNumber * -1.5
      // marginLeft -= (selectedNumber * 5);
    }

    const events = {
      none: {},
      ready: {
        onClick: handleSelect
      },
      spread: {
        onClick: () => {
          applyHoverAndView()
        },
        onMouseOver: doHoverPosition,
        onTouchStart: doHoverPosition
      }
    }

    return (
      <div
        id={id}
        ref={id}
        key={id}
        className={`tarot-card ${selectClass} ${viewingClass} ${hoverClass} ${viewClass} ${orientationClass} ${cardDisable}`}
        data-selected={isSelected}
        data-index={selectedNumber}
        style={{
          transform: thisTransform,
          marginTop: `${marginTop}px`,
          zIndex
        }}
        {...events[eventType]}
        /* accessbility */
        role='button'
        tabIndex={order}
        aria-pressed={isSelected}
      >
        {(!isSelected && indexViewing > 0 && shuffled === 'ready') &&
          (
            <div className='view-index font-serif'>
              {numberViewIndex}
            </div>
          )}
        {
          (isSelected) &&
          (
            <div className={`view-index font-serif order-${order} ${id} ${orientation}`}>
              {numberSelectIndex}
              {/* <div className="card-position font-sans">
                {numbers[positionNumber]}
                {' '}
                -
                {' '}
                {spreadTitle}
              </div> */}
            </div>
          )
        }
        <div className='content'>
          <div className='img-container'>
            <img
              className={`back order-${order} ${id} ${orientation}`}
              data-order={order}
              src={`/${deck}/Back.jpg`}
              alt=''
              onError={(e) => { e.target.src = '/cards/empty.png' }}
            />
            {cardMarkup}
          </div>
          <div className='card-name'>
            {name}
          </div>
          {descriptionMarkup}
        </div>
      </div>
    )
  }
}

export default Card
