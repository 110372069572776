const doSelect = (cardID, orientation, state) => {
  const newSelected = state.selected.slice()
  const currentSpreadHash = Math.ceil(new Date().getTime() / 1000)

  // (cardID, orientation, state);
  if (state.shuffled === 'ready') {
    const { max } = state.spread
    const newOrientation = state.orientation.slice()
    const numberSelected = newSelected.length + 1

    if (numberSelected <= max && cardID !== undefined && newSelected.indexOf(cardID) < 0) {
      newSelected.push(cardID)
      newOrientation.push(orientation)

      if (newSelected.length < max) {
        return {
          selected: newSelected,
          orientation: newOrientation
        }
      }
      return {
        selected: newSelected,
        orientation: newOrientation,
        shuffled: 'done',
        locked: true,
        currentSpread: state.spread,
        allSelected: true,
        currentSpreadHash
      }
    }
  }
  return false
}

export default doSelect
