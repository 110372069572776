import React from 'react'
import { Link } from '@reach/router'
import { months } from 'data/config'
import '../pagination.css'

const MonthPagination = (props, context) => {
  const {
    activeDates,
    currentTime,
    scrollToMonth
  } = props

  const activeMonths = []
  const years = []
  const now = new Date().getFullYear()
  let currentMonth = ''
  let baseTime = null
  let prevYear = null

  if (currentTime !== null && currentTime !== undefined) {
    if (!isNaN(currentTime)) {
      baseTime = currentTime * 1000
    } else {
      baseTime = currentTime
    }
    const currentDate = new Date(baseTime)
    currentMonth = months[11 - currentDate.getMonth()]
  }

  if (Array.isArray(activeDates)) {
    activeDates.forEach((ymd, i) => {
      const date = new Date(ymd)
      const month = date.getMonth()
      const monthReversed = 11 - month
      activeMonths.push(monthReversed)

      if (i === 0) {
        const currentYear = date.getFullYear()
        years.push(currentYear)
        years.push(currentYear - 1)
        prevYear = currentYear - 1
        if (currentMonth === '') {
          currentMonth = months[monthReversed]
        }
      }
    })
  }

  const renderMonths = () => {
    const monthList = months.map((m, index) => {
      const activeClass = activeMonths.indexOf(index) >= 0 ? 'active' : ''
      const currentClass = m === currentMonth ? 'current' : ''
      return (
        <li
          key={`month_${m}`}
          role='menuitem'
          className={`${activeClass} ${currentClass}`}
          month={m}
          onClick={() => scrollToMonth(m, years[0])}
          onKeyPress={() => scrollToMonth(m, years[0])}
        >
          <span>{m}</span>
        </li>
      )
    })
    return monthList
  }

  return (
    <div className='pagination month'>
      <ul role='menu'>
        {now > years[0] && (
          <li className='prev-year'>
            <Link to={`/history/${years[0] + 1}#top`}>
              {years[0] + 1}
              <span />
            </Link>
          </li>
        )}
        <li className='year-top'>
          <Link to={`/history/${years[0]}#top`}>
            {years[0]}
            <span />
          </Link>
        </li>
        {renderMonths()}
        <li className='year-bottom'>
          <Link to={`/history/${prevYear}#top`}>
            {years[1]}
            <span />
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default MonthPagination
