const Page = {
  title: 'HaCker: 駭客塔羅',
  siteTitle: 'HaCker: 駭客塔羅'
}

const Consent = {
  checked: false
}

const initState = {
  share: '',
  Page,
  Consent,
  ready: false,
  doNotTrack: true
}

export default initState
