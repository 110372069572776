import React from 'react'

import HistoryCard from 'components/History/HistoryCard'

import spreads from 'data/spreads'
import cards from 'data/zh/cards.json'
import images from 'data/images.js'

import './index.css'

class HistoryList extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props)

    this.state = {
      currentTime: null,
      currentID: null
    }

    this.updateIndicator = this.updateIndicator.bind(this)
  }

  componentDidMount () {
    this.jump()
  }

  updateIndicator (date, uuid) {
    const { updateCurrentHistory } = this.props
    if (date !== undefined && uuid !== undefined) {
      this.setState({
        currentTime: date,
        currentID: uuid
      })
      this.jump(uuid, 'smooth')
      updateCurrentHistory(date, uuid)
    }
  }

  // eslint-disable-next-line class-methods-use-this
  jump (id, behavior = 'instant') {
    let thisID = ''
    if (id === null || id === undefined) {
      let { hash } = window.location
      hash = hash.replace('#', '')
      thisID = hash
    } else {
      thisID = id
    }
    if (thisID !== undefined) {
      const el = document.getElementById(thisID)
      if (el !== null && el !== undefined) {
        el.scrollIntoView({
          behavior,
          block: 'start'
        })
      }
    }
  }

  render () {
    const {
      historySorted,
      currentID
    } = this.props

    const { ids, data } = historySorted
    const sectionMarkup = []

    if (ids.length > 0) {
      Object.keys(data).forEach((date, hIndex) => {
        const items = data[date]
        const itemsMarkup = []
        const dateMarkup = ''
        if (items.length > 0) {
          items.forEach((item, i) => {
            const { timestamp, time } = item
            const {
              spreadId, question
            } = item

            const { positions, name } = spreads[spreadId]
            let text = null
            // return if no position data
            if (positions === undefined) {
              return
            }

            if (question !== null) {
              // eslint-disable-next-line prefer-destructuring
              text = question.text
            }
            if (text === null || text === undefined) {
              text = dateMarkup
            }

            itemsMarkup.push(
              <HistoryCard
                key={`${time}_${i * 10}`}
                time={time}
                index={items.length - i}
                historyData={item}
                name={name}
                spreadId={spreadId}
                date={timestamp * 1000}
                question={text}
                cards={cards}
                images={images}
                positions={positions}
                isReviewMode={false}
                updateIndicator={this.updateIndicator}
                currentID={currentID}
              />
            )
          })
        }

        const thisSection = (
          <section
            key={`${date}_${hIndex * 10}`}
            className='history-day'
          >
            {itemsMarkup}
          </section>
        )

        sectionMarkup.push(thisSection)
      })
    }

    return sectionMarkup
  }
}

export default HistoryList
