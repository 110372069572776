import log from '../log'

async function migrateDB (db) {
  if (!db) { return }
  const tx = db.transaction('history', 'readwrite')
  const store = tx.objectStore('history')

  const years = ['2018', '2019', '2020']

  years.forEach(async (year) => {
    const records = JSON.parse(localStorage.getItem(`haCker_history_${year}`))
    if (records) {
      const migrateHistory = records.index.map((history) => {
        const {
          uuid,
          timestamp
        } = history

        const details = JSON.parse(localStorage.getItem(`haCker_history_${uuid}`))
        const date = new Date(timestamp * 1000).toISOString()
        return store.put({
          ...history,
          date,
          spreadId: details.currentSpread.id,
          question: details.question,
          selected: details.selected,
          orientation: details.orientation
        }, uuid)
      })

      await Promise.all([
        ...migrateHistory,
        tx.done
      ]).catch((err) => {
        log.error(err, 'error')
      })
    }
  })
  // while (cursor) {
  //   console.log(cursor.key, cursor.value, 'test');
  //   cursor = await cursor.continue();
  // }
}

export default migrateDB
