import { createMuiTheme } from '@material-ui/core/styles'
// import baseTheme from 'material-ui/styles/baseTheme';
// import * as Colors from 'material-ui/styles/colors';
// import { fade } from 'material-ui/utils/colorManipulator'

// https://material-ui.com/customization/palette/

const theme = createMuiTheme({
  // spacing: factor => `${0.125 * factor}rem`,
  typography: {
    fontFamily: 'Alegreya Sans, Roboto, Microsoft YaHei, sans-serif',
    fontSize: 14
  },
  palette: {
    type: 'dark',
    primary: {
      light: '#333',
      main: '#111',
      dark: '#000',
      contrastText: '#fff'
    },
    secondary: {
      light: 'transparent',
      main: '#fff',
      dark: '#1d7997',
      contrastText: '#000'
    }
  }
})

export default theme
