const sortHistoryByDate = (history, order) => {
  if (history === null) {
    return null
  }

  // const { index } = history
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const historyItems = {}
  const newIDs = []

  if (history !== undefined && history.length > 0) {
    // sort modifies original array
    history.sort((a, b) => {
      let timestampA = a
      let timestampB = b
      if (a !== null && typeof a === 'object') {
        timestampA = a.timestamp
      }
      if (b !== null && typeof b === 'object') {
        timestampB = b.timestamp
      }
      if (order.indexOf(':desc') >= 0) {
        return timestampA - timestampB
      }
      return timestampB - timestampA
    })

    history.forEach((data) => {
      let timestamp = data
      let uuid = ''
      if (data !== null && typeof data === 'object') {
        timestamp = data.timestamp
        uuid = data.uuid
      }
      let date = new Date(timestamp * 1000)

      const day = (date.getDate() < 10) ? `0${date.getDate()}` : date.getDate()
      const year = date.getFullYear()
      const month = months[date.getMonth()]
      const M = (date.getMonth() + 1 < 9) ? `0${date.getMonth() + 1}` : date.getMonth() + 1
      const h = (date.getHours() < 10) ? `0${date.getHours()}` : date.getHours()
      const m = (date.getMinutes() < 10) ? `0${date.getMinutes()}` : date.getMinutes()
      const s = (date.getSeconds() < 10) ? `0${date.getSeconds()}` : date.getSeconds()

      const ymd = `${year}-${M}-${day}`
      const saveTime = `${h}:${m}:${s}`
      date = `${month} ${day}-${year}`

      // const dataKey = `haCker_history_${uuid}`
      // let saveData = localStorage.getItem(dataKey)

      // if (saveData === null) {
      //   return
      // }

      newIDs.push(timestamp)
      // saveData = JSON.parse(saveData)

      if (historyItems[ymd] !== undefined) {
        historyItems[ymd].push({
          date: ymd,
          time: saveTime,
          id: `haCker_history_${uuid}`,
          uuid,
          day,
          month,
          year,
          ...data
        })
      } else {
        historyItems[ymd] = [{
          date: ymd,
          time: saveTime,
          id: `haCker_history_${uuid}`,
          uuid,
          day,
          month,
          year,
          ...data
        }]
      }
    })
  }
  return {
    ids: newIDs,
    data: historyItems,
    lastID: history.lastID
  }
}

const sortHistory = (history, order) => {
  let historySorted
  if (order.indexOf('date') >= 0) {
    historySorted = sortHistoryByDate(history, order)
  }
  return historySorted
}

export default sortHistory
