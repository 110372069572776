
import React from 'react'

import './cardinfo.css'

// eslint-disable-next-line react/prefer-stateless-function
class CardInfo extends React.Component {
  render () {
    const { info, images, orientation, positionNumber, spreadPosition } = this.props
    const {
      id,
      desc,
      meaning_rev, // eslint-disable-line
      meaning_up, // eslint-disable-line
      name,
      type,
      value_int, // eslint-disable-line
    } = info

    const numbers = ['O', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII',
      'VIII', 'IX', 'X', 'XI', 'XII', 'XIII', 'XIV', 'XV', 'XVI',
      'XVII', 'XIII', 'XIX', 'XX', 'XXI', 'XXII', 'XXIII']
    let spreadTitle = ''
    let descriptionMarkup = ''
    const orientationClass = orientation
    const cardURL = images[id]

    if (spreadPosition !== undefined) {
      spreadTitle = spreadPosition.title || null
    }

    // console.log(images);

    if (desc !== undefined && desc.length > 0) {
      const meaningUp = meaning_up.split('\n').map((item, index) => (
        <p key={`up-${index * 10}`}>
          {item}
          <br />
        </p>
      ))
      const meaningRev = meaning_rev.split('\n').map((item, index) => (
        <p key={`rev-${index * 10}`}>
          {item}
          <br />
        </p>
      ))
      const newDesc = desc.split('\n').map((item, index) => (
        <p key={`desc-${index * 10}`}>
          {item}
          <br />
        </p>
      ))

      descriptionMarkup = (
        <div className='info'>
          <div
            className='des font-sans'
          >
            <div className='spread-position'>
              {numbers[positionNumber]}
              {' '}
              -
              {' '}
              {spreadTitle}
            </div>
            <div className='info-image'>
              <img src={cardURL} alt='' />
            </div>
            <h2>
              {type === 'major' ? `${numbers[value_int]} ` : ''}
              {name}
            </h2>
            <div className='orientation'>
              {orientationClass !== 'rev' ? '正位' : '逆位'}
            </div>
            {(orientationClass !== 'rev') ? meaningUp : meaningRev}
            <br />
            <b>基本牌義</b>
            {newDesc}
            <br />
            <br />
          </div>
        </div>
      )
    }
    return (
      <div className='card-info'>
        {descriptionMarkup}
      </div>
    )
  }
}

export default CardInfo
