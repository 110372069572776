import getUrlParameter from 'helpers/getUrlParameter'
import codeFile from 'data/code.json'

const hasCorrectCode = () => {
  if (getUrlParameter('o').length > 0 || localStorage.getItem('code') === codeFile.code) {
    return true
  }
  return false
}

export default hasCorrectCode
