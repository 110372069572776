import React, { useContext, useEffect, useState } from 'react'
import { navigate } from '@reach/router'

import HistoryList from 'components/History/HistoryList'
import MonthPagination from 'components/Pagination/Month'
import Button from '@material-ui/core/Button'

// import sanitizeHistoryData from 'helpers/sanitizeHistoryData'
import sortHistoryFn from 'reducers/sortHistory'

import { DB } from 'context/DB'
// import log from 'lib/log'
import { months } from 'data/config'
import './history.css'

async function loadHistory (db, year, setHistory) {
  if (!db) { return }
  const keyRangeValue = IDBKeyRange.bound(Number(year).toString(), (Number(year) + 1).toString(), true, false)
  const cursor = await db.transaction('history').store.index('date').openCursor(keyRangeValue, 'prev')
  const history = []
  if (!cursor) {
    setHistory(null)
    return
  }
  for await (const list of cursor) {
    history.push(list.value)
  }

  const sorted = sortHistoryFn(history, 'date', 'desc')
  setHistory(sorted)
}

const History = (props) => {
  const db = useContext(DB)
  let { year } = props
  year = year === undefined ? new Date().getFullYear() : year

  const [history, setHistory] = useState(undefined)
  const [state, setState] = useState({
    // history,
    currentTime: null,
    currentID: null
    // order: 'date:desc',
  })

  useEffect(() => {
    if (db && !history) {
      loadHistory(db, year, setHistory)
    }
  }, [db, year, history])

  useEffect(() => {
    if (history && history.data) {
      const dates = Object.keys(history.data)
      if (dates[0].indexOf(year) < 0) {
        loadHistory(db, year, setHistory)
      }
    }
  }, [db, state, year, history])

  function updateCurrentHistory (time, uuid) {
    setState({
      currentTime: time,
      currentID: uuid
    })
  }

  function scrollToMonth (m, y) {
    const currentMonthIndex = 11 - months.indexOf(m) + 1
    const mm = (currentMonthIndex < 10) ? `0${currentMonthIndex}` : currentMonthIndex
    const ym = `${y}-${mm}-`

    let targetHistory
    let targetUUID
    let lastTimestamp = null

    if (history !== undefined) {
      const { data } = history
      if (data !== undefined) {
        Object.keys(data).forEach((date) => {
          if (lastTimestamp === null) {
            if (date.indexOf(ym) >= 0) {
              const dayData = data[date]
              if (dayData.length > 0) {
                lastTimestamp = dayData[0].timestamp
                // eslint-disable-next-line prefer-destructuring
                targetHistory = dayData[0]
              }
            }
          }
        })

        targetUUID = targetHistory.uuid
        if (targetUUID !== undefined) {
          const el = document.getElementById(targetUUID)
          if (el !== null) {
            el.scrollIntoView({
              behavior: 'instant'
            })
            setState({
              currentID: targetUUID,
              currentTime: lastTimestamp
            })
          }
        }
      }
    }
  }

  const { currentTime, currentID } = state

  if (history === null) {
    return (
      <div className='history relative'>
        <div className='absolute-with-scroll'>
          <div className='content' id='top'>
            <h1>History</h1>
            <div className='container'>
              <div className='no-history'>
                {`No entires found for ${year}.`}
                <br /><br />
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => navigate('/history')}
                  onKeyPress={() => navigate('/history')}
                >
                  Back to history
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const activeDates = history && history.data ? Object.keys(history.data) : null

  if (!activeDates) { return '' }

  // const { ids } = historySorted

  return (
    <div className='history relative'>
      <div className='absolute-with-scroll'>
        <div className='content' id='top'>
          <h1>History</h1>
          <div className='container'>
            <ul className='tabs row' style={{ display: 'none' }}>
              <li className='tab col s3 m3'>
                <a href='#all' className='active'>All</a>
              </li>
              <li className='tab col s3 m3'>
                <a href='#favourite'>
                  <i className='material-icons'>
                    star
                  </i>
                </a>
              </li>
              <li className='tab col s3 m3'>
                <a href='#type'><i className='material-icons'>style</i></a>
              </li>
              <li className='tab col s3 m3'>
                <a href='#type'><i className='material-icons'>search</i></a>
              </li>
              <li className='indicator' />
            </ul>
          </div>
          <div className='container'>
            {/* <div className="stats">
              Total
              {' '}
              { ids.length }
              records.
            </div> */}
          </div>
          <MonthPagination
            activeDates={activeDates}
            updateCurrentHistory={updateCurrentHistory}
            currentTime={currentTime}
            currentID={currentID}
            scrollToMonth={scrollToMonth}
          />
          <HistoryList
            currentID={currentID}
            historySorted={history}
            updateCurrentHistory={updateCurrentHistory}
          />
        </div>
      </div>
    </div>
  )
}

export default History
