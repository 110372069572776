import React from 'react'

class TarotCard extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props)

    this.state = {}
  }

  render () {
    const {
      cardID,
      cardIndex,
      orientation,
      src
    } = this.props

    return (
      <div
        className={`tarot-card selected ${orientation}`}
        data-index={cardIndex + 1}
      >
        <div className='content'>
          <div className='img-container'>
            <img
              className='front'
              src={src}
              alt={cardID}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default TarotCard
