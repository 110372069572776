import spreadsData from 'data/zh/spreads.json'
import getUrlParameter from 'helpers/getUrlParameter'
let deck = getUrlParameter('deck')

if (!deck || deck.length < 0) {
  deck = localStorage.getItem('deck')
    ? localStorage.getItem('deck')
    : 'cards'
}

const images = {
  m01: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_01.jpg?alt=media&token=79dd0d99-07b9-4ba7-b586-98ec09c732b4`,
  m02: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_02.jpg?alt=media&token=b5fe6486-5017-4ca9-86ad-582814b058c5`,
  m03: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_03.jpg?alt=media&token=0e87ca99-b7d1-44a5-81a7-c6661aa9693b`,
  m04: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_04.jpg?alt=media&token=481a29a4-07e3-4ace-9830-09450fc2593e`,
  m10: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_10.jpg?alt=media&token=5b974935-97a5-4dc2-8c24-13acbe7cb9ae`,
  m07: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_07.jpg?alt=media&token=52d79291-d325-4490-8a99-19d5f6218803`,
  m05: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_05.jpg?alt=media&token=12d45a3d-1f3e-4a24-aead-be8c1464e87f`,
  m09: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_09.jpg?alt=media&token=645a2ef1-f95e-472a-aa19-8b9bfbf7d16c`,
  m08: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_08.jpg?alt=media&token=1b5da041-4f61-4cde-9881-922ad4ded0a8`,
  m11: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_11.jpg?alt=media&token=b38a3f4b-a0d7-41d0-91ff-dd0e14f0cd79`,
  m06: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_06.jpg?alt=media&token=ba2410a3-c4ff-42e6-8483-dd0e2c6e04d4`,
  m12: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_12.jpg?alt=media&token=835455a5-e3ed-4c52-8077-ba24d07669a4`,
  m13: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_13.jpg?alt=media&token=9f4afcd5-dcb1-4a1e-ba7c-9d1b0ecbaf04`,
  m15: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_15.jpg?alt=media&token=df49c349-188d-43d2-88e6-127db122a259`,
  m14: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_14.jpg?alt=media&token=61466e67-a165-41b1-b186-a5cfe7ea2e89`,
  m17: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_17.jpg?alt=media&token=40c72880-8c42-495d-9673-3fc31be6ea1b`,
  m00: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_00.jpg?alt=media&token=654937dc-e1c8-4f8e-a1ad-2241a5019899`,
  m20: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_20.jpg?alt=media&token=ab9bd7e4-cb77-4afd-9c32-a6f47393fd74`,
  m18: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_18.jpg?alt=media&token=4f2f8ef6-510b-4b62-a186-1403ebb8d606`,
  m19: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_19.jpg?alt=media&token=96afd674-eae8-4676-92b5-ba2575fc7afa`,
  m16: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_16.jpg?alt=media&token=7b02c0ba-946c-45c3-80bd-3984150d49d3`,
  m21: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FM_21.jpg?alt=media&token=13959514-d9fe-4108-8e18-310561c8877f`,
  w11: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FWand_11.jpg?alt=media&token=54d0a9fa-3c15-4b90-b0e0-36756327619f`,
  w03: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FWand_03.jpg?alt=media&token=a260a0c1-c157-4b93-9693-48ec551c3108`,
  w04: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FWand_04.jpg?alt=media&token=cc9b2fcd-06c4-4c9c-984b-b001d6cd4fb2`,
  w02: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FWand_02.jpg?alt=media&token=25478a99-c8f9-4f81-97c3-70f1d289123b`,
  w05: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FWand_05.jpg?alt=media&token=e6c91fa7-9425-4bb0-9c9d-17495b83f54f`,
  w12: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FWand_12.jpg?alt=media&token=d81cdbf4-23f8-4818-bd57-ddc47b22d772`,
  w14: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FWand_14.jpg?alt=media&token=2d2f662d-7851-4d3e-ba7d-2fb48316b581`,
  w01: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FWand_01.jpg?alt=media&token=b4c348e8-d315-41dc-a6c8-7843239832de`,
  w06: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FWand_06.jpg?alt=media&token=c588cc80-8599-4de6-b6f5-b575fcc56fd3`,
  w07: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FWand_07.jpg?alt=media&token=1a53a7c5-7f13-4982-a4ee-66f0a9a08acf`,
  w09: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FWand_09.jpg?alt=media&token=445c8754-6b1c-4147-9ebb-dac016a59eef`,
  c11: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FCup_11.jpg?alt=media&token=df06e7c2-023c-4a1a-ae26-04c27ba6f565`,
  w13: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FWand_13.jpg?alt=media&token=fd18d371-80e5-4716-ad82-03ab8ad3a5ef`,
  c12: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FCup_12.jpg?alt=media&token=d4d9674d-8165-4c29-ac25-3e4b6d36fd58`,
  w10: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FWand_10.jpg?alt=media&token=a1dd5002-a02f-4b18-869d-d1180d8b2cd3`,
  w08: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FWand_08.jpg?alt=media&token=b4822387-6211-46de-b995-90491e0c5619`,
  c04: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FCup_04.jpg?alt=media&token=d5c4542e-c44e-4235-9c9e-6b55b4493e49`,
  c13: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FCup_13.jpg?alt=media&token=b7934057-ee90-4aad-84d4-de85515cb251`,
  c02: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FCup_02.jpg?alt=media&token=cc23d51c-eb18-4f55-9e56-3983a2157ce9`,
  c08: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FCup_08.jpg?alt=media&token=5d88af71-2348-422c-889f-d2a3ad062d2c`,
  c06: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FCup_06.jpg?alt=media&token=b37bafe5-b15e-4711-bf20-096cb9476795`,
  c03: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FCup_03.jpg?alt=media&token=fcda42e4-8d10-4120-84bb-8d8bca8477f4`,
  c14: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FCup_14.jpg?alt=media&token=589b424f-c95e-4374-851d-d008e5216d29`,
  c07: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FCup_07.jpg?alt=media&token=837209cf-de9a-45c8-bdb7-a280d33d33cb`,
  c01: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FCup_01.jpg?alt=media&token=f36c5a15-5d42-4865-a428-6eb3887b1c4e`,
  p11: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FPentacle_11.jpg?alt=media&token=5cef6e4f-8b94-4db7-979b-86807f9d5dc4`,
  p12: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FPentacle_12.jpg?alt=media&token=69c10b2d-99a9-424c-98da-0f48a094bb40`,
  c09: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FCup_09.jpg?alt=media&token=c3fc5884-9533-4372-8c2e-12eb8a42f906`,
  c05: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FCup_05.jpg?alt=media&token=12583036-5c6f-4cd2-98c9-0bc93322af3e`,
  p14: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FPentacle_14.jpg?alt=media&token=9e8eefab-f676-4b69-bf9b-e04f1b38087f`,
  c10: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FCup_10.jpg?alt=media&token=b35421f7-cc95-465f-89ad-5fc0e36b7578`,
  p02: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FPentacle_02.jpg?alt=media&token=2c0eef03-fa35-4c00-b00a-f290cb94e6f0`,
  p03: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FPentacle_03.jpg?alt=media&token=8950fdd1-2820-4d55-be58-57f364c8e7a9`,
  p06: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FPentacle_06.jpg?alt=media&token=113305ef-a908-40b1-b18d-408efd32f8a8`,
  p04: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FPentacle_04.jpg?alt=media&token=42b016de-066d-4e63-8eed-85794aa001f2`,
  p07: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FPentacle_07.jpg?alt=media&token=f9d3f7af-bd5b-44d6-bd8b-65b50adc870d`,
  p08: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FPentacle_08.jpg?alt=media&token=16756c28-c2e3-4d48-8291-970eb2280858`,
  p05: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FPentacle_05.jpg?alt=media&token=94f44dbe-6a15-4218-892c-3af9082163e7`,
  p13: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FPentacle_13.jpg?alt=media&token=4b1f0b88-73ef-4fdc-aa5b-c386128e7498`,
  p01: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FPentacle_01.jpg?alt=media&token=f198dac7-2472-4ff5-a66e-1979ae5efd95`,
  p10: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FPentacle_10.jpg?alt=media&token=bd32403f-ecbd-4d64-b85b-12c03cee7d2b`,
  s11: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FSword_11.jpg?alt=media&token=42e65eac-d8d8-4277-98fe-b3513ddc3215`,
  p09: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FPentacle_09.jpg?alt=media&token=2cf3cbda-afe7-4eae-804b-587994319b56`,
  s13: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FSword_13.jpg?alt=media&token=4fd93679-6b62-4ddd-bd41-cdc3b4c10b93`,
  s14: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FSword_14.jpg?alt=media&token=231ff739-a344-498c-90ca-7840189e5148`,
  s02: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FSword_02.jpg?alt=media&token=ed43eaf6-9635-4be1-b0eb-4ebc64ebdda5`,
  s01: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FSword_01.jpg?alt=media&token=853262ff-2017-42a7-892a-17ba9d4bf9f5`,
  s04: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FSword_04.jpg?alt=media&token=8bb94a36-1437-4bcd-8699-24b0cf2a0b15`,
  s03: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FSword_03.jpg?alt=media&token=4edb80e5-9cf2-46a3-bcf8-2a8f58b06be7`,
  s12: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FSword_12.jpg?alt=media&token=608727a1-b2ff-44ad-84b2-98f86270f3f7`,
  s05: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FSword_05.jpg?alt=media&token=d552cf11-0388-4d3e-b8a7-c9fbbbb5b1c4`,
  s06: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FSword_06.jpg?alt=media&token=05997574-c61f-4a32-a093-5790c19ddbe1`,
  s07: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FSword_07.jpg?alt=media&token=809363f1-612a-498d-8073-af078b76f8eb`,
  s10: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FSword_10.jpg?alt=media&token=38650811-4450-467a-9410-0aa0a8b27cad`,
  s08: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FSword_08.jpg?alt=media&token=c989fcc1-5396-4f33-898b-322f10b0e793`,
  s09: `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/${deck}%2FSword_09.jpg?alt=media&token=abc651d4-c059-4332-a812-097c58b02c07`,
  empty: 'https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/cards/empty.png',
  matrix: '/app/matrix.jpg',
  back: `/${deck}/Back.jpg`,
  history: '/app/history.jpg'
}

Object.keys(spreadsData).forEach((key) => {
  const { spreads } = spreadsData[key]
  spreads.forEach((spread) => {
    const spreadID = spread.id
    images[spreadID] = `https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o/app%2F${spreadID}.jpg?alt=media`
  })
})

export default images
