const doShuffleStack = (e, state) => {
  if (e.target.attributes['data-order'] === undefined || e === null) {
    return null
  }

  const totalCards = state.stack.length
  const { shuffled } = state
  const out = { x: 0, y: 0 }
  let newStack

  if (shuffled === 'shuffling') {
    const prevPosition = state.mousePositions
    const order = e.target.attributes['data-order'].value
    // const order = index;
    const maxIndex = totalCards - 1
    let max = maxIndex
    let min = 0
    if (e.type === 'touchstart' || e.type === 'touchmove' || e.type === 'touchend' || e.type === 'touchcancel') {
      const touch = e.targetTouches[0] || e.targetTouches[0]
      out.x = touch.pageX
      out.y = touch.pageY
    } else if (e.type === 'mousedown' || e.type === 'mouseup' ||
      e.type === 'mousemove' || e.type === 'mouseover' || e.type === 'mouseout' ||
      e.type === 'mouseenter' || e.type === 'mouseleave') {
      out.x = e.pageX
      out.y = e.pageY
    }

    if (out.y - prevPosition.y > 0) {
      min = (order + 1) > max ? max : (order + 1)
      max = maxIndex
    } else {
      min = 0
      max = (order - 1 < 0) ? 0 : (order - 1)
    }

    newStack = state.stack.slice()
    let thisOrder = order

    for (let n = 1; n <= 5; n += 1) {
      thisOrder = (order + n) < (maxIndex - 5) ? order + n : order - n
      if (thisOrder < 0) { thisOrder = 0}
      const newOrder = Math.floor((Math.random() * ((max - min) + 1)) + min)
      const newOrientation = Math.random() > 0.5 ? 'up' : 'rev'
      const element = newStack[thisOrder]

      element.orientation = newOrientation
      let middle = 0
      if (newOrder - thisOrder > 0) {
        middle = Math.floor((Math.random() * ((newOrder - thisOrder) + 1)) + thisOrder)
      } else {
        middle = Math.floor((Math.random() * ((thisOrder - newOrder) + 1)) + newOrder)
      }
      newStack.splice(thisOrder, 1)
      newStack.splice(newOrder, 0, element)

      const middleItem = newStack[middle]
      newStack.splice(middle, 1)
      newStack.splice(middle, 0, middleItem)
    }
  }

  const data = { out, newStack }
  return data
}

export default doShuffleStack
