import React from 'react'
// import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
// import Link from '@material-ui/core/Link'

// import { navigate } from '@reach/router';
// import './footer.css';

class Privacy extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props)

    // const prevOptOut = localStorage.getItem('noGA');
    // const now = Date.now();
    this.state = {
      // optout: prevOptOut,
    }
  }

  // doOptOut() {
  //   localStorage.setItem('noGA', Date.now());
  // }

  render () {
    return (
      <Container maxWidth='sm'>
        <div className='privacy relative'>
          <div className='container'>
            <div className='row'>
              <div className='col s12'>
                <Typography variant='h4' component='h2' gutterBottom>
                  Privacy Policy
                </Typography>
                <p />
                <Typography variant='h5' component='h3' gutterBottom>
                  Google Analytics
                </Typography>
                <p>We use Google Analytics on shiawasenashi.com and all its subdomains.</p>
                <p>
                  Google Analytics is a piece of software that grabs data about our
                  visitors (you). It's something like an advanced server log.
                </p>
                <p><strong>What does Google Analytics record?</strong></p>
                <ul>
                  <li>What website you came from to get here.</li>
                  <li>How long you stay for.</li>
                  <li>What kind of computer you're using.</li>
                  <li>And quite a bit more.</li>
                </ul>
                <p><strong>What do we do with your data?</strong></p>
                <p>
                  The tracking information allows us to better understand the kind of people who come
                  to my site and what content they're reading. This allows us to make better decisions
                  about design and writing.
                </p>
                <p>
                  Occasionally, we will compile aggregate statistics about the number of visitors
                  this site receives and browsers being used. No personally identifying data is
                  included in this type of reporting.
                </p>
                <p>
                  All of our activities fall within the bounds of the
                  {' '}
                  <a href='http://www.google.com/analytics/tos.html' color='secondary'>
                    Google Analytics Terms of Service
                  </a>
                  .
                </p>
                <p><strong>Want to opt out of tracking?</strong></p>
                {/* <p><a href="#" onClick={this.doOptOut}>Opt out of tracking</a>
                (sets a cookie to disable our tracking for the next 92 days).&nbsp;
                  <em>
                    This only works on shiawasenashi.com and its subdomains.
                  </em>
                </p> */}
                {/* <p>We honour your browser's DO NOT TRACK settings.
                If you already have it setup, our websites will not be tracking
                you on Google Analytics.</p> */}
                <p>
                  You can also
                  {' '}
                  <a href='http://www.google.com/privacy_ads.html' color='secondary'>
                    opt out of Google's advertising tracking cookie
                  </a>
                  or
                  {' '}
                  <a href='http://tools.google.com/dlpage/gaoptout?hl=en'>
                    use a browser plugin to opt out of all Google Analytics tracking software
                  </a>
                  .
                </p>
                {/* <p><strong>Your Current Opt-Out Status</strong>: Yes, your
                opt-out is still active.<br /></p> */}
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

export default Privacy
