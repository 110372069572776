const getShareURL = (state) => {
  const {
    currentSpread,
    selected: selectedFromState,
    orientation
  } = state

  let url = ''
  const host = (window.location.port.length > 0) ? `${window.location.hostname}:${window.location.port}` : window.location.hostname

  if (state === null || state === undefined) {
    return url
  }

  if (currentSpread !== undefined && currentSpread !== null) {
    let spreadID = state.currentSpread.id
    let selected = selectedFromState
    let o = orientation

    if (Object.prototype.toString.call(spreadID) !== '[object String]') {
      spreadID = ''
    }
    if (Object.prototype.toString.call(selected) !== '[object Array]') {
      selected = []
    }
    if (Object.prototype.toString.call(o) !== '[object Array]') {
      o = []
    }

    selected = selected.join(',')

    o = o.join('')
    o = o.replace(/up/g, 1).replace(/rev/g, 0)

    // selected = btoa(selected);
    // o = btoa(o);
    url = `${window.location.protocol}//${host}/share`

    if (spreadID.length > 0) {
      url = `${url}?spread=${spreadID}`
    }
    if (selected.length > 0) {
      url = `${url}&selected=${selected}`
    }
    if (o.length > 0) {
      url = `${url}&o=${o}`
    }
  }
  return url
}

export default getShareURL
