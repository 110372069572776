const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    const temp2 = array[j]

    const iOrientation = Math.random() > 0.5 ? 'up' : 'rev'
    const jOrientation = Math.random() > 0.5 ? 'up' : 'rev'

    temp.orientation = iOrientation
    temp2.orientation = jOrientation

    array[i] = temp2 /* eslint no-param-reassign: ["error", { "props": false }] */
    array[j] = temp /* eslint no-param-reassign: ["error", { "props": false }] */
  }
  return array
}

export default shuffle
