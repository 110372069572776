import React, { createContext, useReducer } from 'react'

const SET_USER = 'SET_USER'
const initialState = undefined
export const User = createContext(initialState)

export const UserProvider = ({ children }) => {
  const { Provider } = User
  const [userState, dispatch] = useReducer((state, action) => {
    const user = action.data

    const userState = user && user.email
      ? {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          uid: user.uid,
          loggedIn: true,
          sessionExpired: false
        }
      : user
    switch (action.type) {
      case SET_USER:
        // const newState = userData
        return userState
      default:
        throw new Error()
    };
  }, initialState)

  const setUser = (data) => {
    dispatch({
      type: SET_USER,
      data
    })
  }
  return <Provider value={{ userState, dispatch, setUser }}>{children}</Provider>
}

const context = { User, UserProvider }

export default context
