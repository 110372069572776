export const imageBase = 'https://firebasestorage.googleapis.com/v0/b/death-project-c.appspot.com/o'

export const months = [
  'Dec',
  'Nov',
  'Oct',
  'Sep',
  'Aug',
  'Jul',
  'Jun',
  'May',
  'Apr',
  'Mar',
  'Feb',
  'Jan'
]
