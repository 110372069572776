const doHoverAndView = (cardID, state) => {
  const newView = state.viewed.slice()
  const prevHover = state.hovered
  if (!state.locked) {
    if (newView.indexOf(cardID) < 0) {
      newView.push(cardID)
    }

    if (prevHover === cardID) {
      return {
        hovered: null
      }
    }
    return {
      hovered: cardID,
      viewed: newView
    }
  }
  return {
    hovered: null
  }
}

export default doHoverAndView
