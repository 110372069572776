import React from 'react'

class Underway extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props)

    this.state = {
    }
  }

  render () {
    return (
      <div className='soon'>
        <div className='container'>
          <div className='row'>
            <div className='col s12'>
              <h2>On its way</h2>
              Still under constructions... check back again in a few more weeks!
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Underway
