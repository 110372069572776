import React from 'react'
import ReactDOM from 'react-dom'
import { IntlProvider } from 'react-intl'
import { ThemeProvider } from '@material-ui/styles'
import theme from 'themes/default'

import Base from 'pages/Base'
import { FirebaseProvider } from './context/Firebase'
import { GoogleAPIProvider } from './context/GoogleAPI'
import { UserProvider } from './context/User'
import { DBProvider } from 'context/DB'
import initialize from 'lib/initialize'

import zh from './react-intl/locale-data/zh'
import ja from './react-intl/locale-data/ja'
import en from './react-intl/locale-data/en'

import 'sanitize.css/sanitize.css'
import './global.css'
import './print.css'
// import { debounce } from '@material-ui/core'

function loadLocaleData (locale: string) {
  switch (locale) {
    case 'zh':
      return zh
    case 'ja':
      return ja
    case 'en':
      return en
    default:
      return zh
  }
}

const language =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language

// const locale = language.split('-')[0]

Promise.all([
  initialize()
]).then(([init]) => {
  const { firebase = null, gapi, db } = init
  const messages = loadLocaleData(language)
  ReactDOM.render(
    (
      <FirebaseProvider value={firebase}>
        <GoogleAPIProvider value={gapi}>
          <DBProvider value={db}>
            <UserProvider>
              <ThemeProvider theme={theme}>
                <IntlProvider
                  locale={language}
                  key={language}
                  defaultLocale='zh'
                  messages={messages}
                >
                  <Base locale='zh' />
                </IntlProvider>
              </ThemeProvider>
            </UserProvider>
          </DBProvider>
        </GoogleAPIProvider>
      </FirebaseProvider>
    ), document.getElementById('project-c')
  )
})

// if ('serviceWorker' in navigator && window.location.href.indexOf('localhost') < 0) {
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js', {
    scope: './'
  }).then((registration) => {
    let serviceWorker
    if (registration.installing) {
      serviceWorker = registration.installing
      // document.querySelector('#kind').textContent = 'installing';
    } else if (registration.waiting) {
      serviceWorker = registration.waiting
      // document.querySelector('#kind').textContent = 'waiting';
    } else if (registration.active) {
      serviceWorker = registration.active
      // document.querySelector('#kind').textContent = 'active';
    }
    if (serviceWorker) {
      // logState(serviceWorker.state);
      serviceWorker.addEventListener('statechange', (e) => {
        // logState(e.target.state);
        // console.log(event);
      })
    }
  }).catch((error) => {
    // Something went wrong during registration. The service-worker.js file
    // might be unavailable or contain a syntax error.
    throw new Error(error)
  })
} else {
  // The current browser doesn't support service workers.
}
