import React from 'react'
import { navigate } from '@reach/router'

import TextField from '@material-ui/core/TextField'

import hasCorrectCode from 'helpers/hasCorrectCode' // eslint-disable-line import/no-extraneous-dependencies
import codeFile from 'data/code.json' // eslint-disable-line import/no-extraneous-dependencies
import './soon.css'

class ComingSoon extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props)
    this.state = {
      codeInput: null,
      code: codeFile.code,
      message: 'Now, try guess the early access code!',
      attempts: 0
    }

    this.onEnterPress = this.onEnterPress.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount () {
    if (hasCorrectCode()) {
      navigate('/')
    }
  }

  onEnterPress (e) {
    if (e.keyCode === 13 && e.shiftKey === false) {
      // e.preventDefault();
      this.handleSubmit(e)
    }
  }

  handleChange (event) {
    this.setState({ codeInput: event.target.value })
  }

  handleSubmit (e) {
    const { codeInput, code } = this.state
    let { attempts } = this.state
    e.preventDefault()
    attempts += 1
    if (codeInput === code) {
      localStorage.setItem('code', codeInput)
      this.props.setValidate(true)
      navigate('/')
    } else if (attempts >= 8) {
      this.setState({
        attempts,
        message: '密碼這種東西... 反白( CTRL + A)試試看？'
      })
    } else if (attempts >= 5) {
      this.setState({
        attempts,
        message: '居然還沒放棄？不過……説不定再試幾次真的猜中？'
      })
    } else if (attempts === 2) {
      this.setState({
        attempts,
        message: 'Nice one, too.'
      })
    } else {
      this.setState({
        attempts,
        message: 'Nice Try'
      })
    }
  }

  render () {
    const { code, message, attempts } = this.state
    const maxLength = code.length
    return (
      <div className='page-soon'>
        <form className='early-release'>
          <img
            className='bg'
            src='/app/matrix.jpg'
            alt='moon'
            onError={(e) => { e.target.src = '/cards/empty.png' }}
          />
          <div className='row'>
            <div className='input-field col s12'>
              <TextField
                color='secondary'
                required
                id='code'
                name='code'
                placeholder='Guess me...!'
                label='Access Code'
                defaultValue=''
                helperText='Enter the passcode'
                data-length='20'
                maxLength={maxLength}
                onInput={this.handleChange}
                onKeyDown={(e) => { this.onEnterPress(e) }}
              />
              {/* <input
                placeholder='Guess me...!'
                id='code'
                name='code'
                type='text'
                className='validate'
                data-length='20'
                maxLength={maxLength}
                onInput={this.handleChange}
                onKeyDown={(e) => { this.onEnterPress(e) }}
              />
              <label htmlFor="code" className="active">Access Code</label> */}
            </div>
            <br /><br />
            <div className='col s12'>
              <button
                className='waves-effect waves-light btn btn-second btn-small'
                onClick={(e) => { this.handleSubmit(e) }}
              >
                Submit
              </button>
              <br />
              <br />
              <div className='message'>
                {message}
              </div>
              {(attempts >= 8)
                ? (
                  <div className='so-hidden'>
                    Thank you for playing.
                    <br />
                    親愛的駭客，你找到密碼啦：
                    <br />
                    <br />
                    <code>{code}</code>
                    <br />
                    <br />
                    密碼可能不定期更新噢 :)
                    <br />
                    <br />
                  </div>
                  )
                : ''}
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default ComingSoon
