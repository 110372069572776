/* eslint camelcase: ["error", {allow: ["UNSAFE_componentWillReceiveProps"]}], */
import React from 'react'
import { throttle } from 'throttle-debounce'
import { navigate } from '@reach/router'

import Stack from 'components/Cards/Stack'
import BoardBackground from 'components/Cards/BoardBackground'
import Controls from 'components/Cards/Controls'

import SpreadInfo from 'components/SpreadInfo'
import Walkthrough from 'components/Walkthrough'
import WalkthroughStart from 'components/Walkthrough/Start'

import doSave from 'reducers/doSave'
import doShuffleStack from 'reducers/doShuffleStack'
import doNext from 'reducers/doNext'
import doHoverAndView from 'reducers/doHoverAndView'
import doSelect from 'reducers/doSelect'
import getShare from 'reducers/getShare'
import doReview from 'reducers/doReview'

// import getPositionMap from 'helpers/getPositionMap';
import shuffle from 'helpers/shuffle'
import getTranslations from 'helpers/getTranslations'
import escapeHTML from 'helpers/escapeHTML'
import getUUID from 'helpers/getUUID'
// import getUrlParameter from 'helpers/getUrlParameter';

// import celtic from 'data/zh/celtic.json';
// import Data from 'data/zh/cards.json';
import images from 'data/images.js'
import CardsInitState from './CardsInitState'
import './cards.css'

import Share from 'components/Share'

const deck = localStorage.getItem('deck') || 'cards'
images.Back = `/${deck}/Back.jpg`
// let thisSpread = {}

class Cards extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props)

    const { currentSpread, blur, question } = this.props
    let { locale, mode, uuid, year } = this.props
    mode = mode === undefined ? 'default' : mode
    locale = locale === undefined ? 'zh' : locale
    year = year === undefined ? new Date().getFullYear() : year
    const stateGiven = getShare(locale)
    const saveData = JSON.parse(sessionStorage.getItem('saveData'))
    mode = (saveData !== null && saveData.mode !== undefined &&
      saveData.mode !== null)
      ? saveData.mode
      : mode

    // remove saveData if error
    this.state = {
      ...this.props,
      ...CardsInitState,
      ...stateGiven,
      cardsData: null,
      selectedPosition: null,
      currentSpreadUUID: uuid,
      historyYear: year
    }

    if (currentSpread !== undefined) {
      this.state.currentSpread = currentSpread
      this.state.positions = currentSpread.positions
    }

    if (blur !== undefined && blur !== null) {
      this.state.blur = blur
    }

    if (mode === 'review') {
      const reviewState = doReview(this.props)
      this.state = {
        ...this.state,
        ...reviewState
      }
    }
    if (mode === 'again') {
      this.state.question = question
    }

    this.doSave = this.doSave.bind(this)
    this.doReset = this.doReset.bind(this)
    this.doSelect = this.doSelect.bind(this)
    this.enableShuffle = this.enableShuffle.bind(this)
    this.doShuffle = this.doShuffle.bind(this)
    // this.doSpread = this.doSpread.bind(this);
    this.doSpreadInfo = this.doSpreadInfo.bind(this)
    this.doReady = this.doReady.bind(this)
    this.loadTranslations = this.loadTranslations.bind(this)

    this.doStart = this.doStart.bind(this)
    this.doNext = this.doNext.bind(this)
    this.doEnd = this.doEnd.bind(this)
    // this.getImage = this.getImage.bind(this);
    this.applyHoverAndView = this.applyHoverAndView.bind(this)
    this.handleShufflingDebounced = throttle(500, this.handleShuffling.bind(this))
    this.updateShuffling = this.updateShuffling.bind(this)
    this.skipToResults = this.skipToResults.bind(this)
    this.doSaveResult = this.doSaveResult.bind(this)
    this.doSaveSpread = this.doSaveSpread.bind(this)
    this.doHandleQuestion = this.doHandleQuestion.bind(this)
    this.doPrint = this.doPrint.bind(this)
  }

  componentDidMount () {
    const { historyData } = this.props
    if (historyData) {
      console.log(historyData)
      this.setState({
        currentSpread: {
          id: historyData.spreadId
        }
      })
    }
    this.loadTranslations()
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const { mode } = this.props
    const { spread } = this.state
    let { currentSpread } = this.state

    if (mode === 'review') {
      currentSpread = this.props.currentSpread; // eslint-disable-line
    } else {
      currentSpread = this.state.currentSpread;  // eslint-disable-line
    }

    let { locale } = this.props
    if (locale === null || locale === undefined) {
      locale = 'zh'
    }
    if (nextProps.currentSpread === null) {
      const spreadID = spread.id
      getTranslations(locale, spreadID).then((data) => {
        // thisSpread = data.spread
        this.setState({
          ...nextProps,
          ...CardsInitState,
          spread: data.spread
        })
      })
    }
    if (nextProps.currentSpread !== undefined) {
      const spreadID = nextProps.currentSpread.id
      const oldSpreadID = currentSpread.id

      if (spreadID !== null && spreadID !== undefined &&
        spreadID !== oldSpreadID) {
        getTranslations(locale, spreadID).then((data) => {
          const cardsData = data.cards
          // thisSpread = data.spread
          this.setState({
            ...CardsInitState,
            spread: data.spread,
            currentSpread: data.spread,
            cardsData
          })
        })
      }
    }

    if (nextProps.question !== null) {
      this.setState({
        question: nextProps.question
      })
    }
  }

  componentDidUpdate () {
    const { mode } = this.props
    if (mode !== 'review' && mode !== 'again') {
      const newData = {
        ...this.state,
        ...this.props
      }
      doSave('saveData', newData)
    }
  }

  doSave () {
    const saveData = JSON.stringify(this.state)
    sessionStorage.setItem('saveData', saveData)
  }

  loadTranslations () {
    const currentSpread = this.state.currentSpread || this.props.currentSpread
    let { locale } = this.state
    if (locale === undefined || locale === null) {
      locale = 'zh'
    }

    const spreadID = currentSpread.id
    getTranslations(locale, spreadID).then((data) => {
      const cardsData = data.cards
      // thisSpread = data.spread
      this.setState({
        spread: data.spread,
        cardsData
      })
    })
  }

  doReset () {
    const { currentSpread } = this.state
    const { redoSelect } = this.props
    sessionStorage.removeItem('saveData')
    this.setState({
      ...CardsInitState,
      spread: currentSpread
    })

    redoSelect()
    navigate('/')
  }

  doReady () {
    this.setState({
      shuffled: 'ready'
    })
  }

  enableShuffle () {
    const { stack } = this.state
    const shuffled = shuffle(stack)
    this.setState({
      shuffled: 'shuffling',
      stack: shuffled,
      allSelected: false
    })
  }

  doShuffle () {
    const { stack } = this.state
    const shuffled = shuffle(stack)
    this.setState({
      shuffled: 'ready',
      stack: shuffled,
      allSelected: false
    })
  }

  doSelect (cardID, orientation) {
    const { viewing } = this.state
    if (cardID !== viewing) {
      this.setState({ viewing: cardID })
    }
    if (cardID === viewing) {
      const newSelectState = doSelect(cardID, orientation, this.state)
      if (newSelectState !== false) {
        this.setState({
          ...newSelectState
        })
      }
    }
  }

  updateShuffling (e) {
    e.persist()
    this.handleShufflingDebounced(e)
  }

  handleShuffling (e) {
    const { shuffled } = this.state

    if (shuffled === 'shuffling') {
      const newData = doShuffleStack(e, this.state)
      if (newData !== null) {
        // return out;
        this.setState({
          // mousePositions: newData.out,
          stack: newData.newStack
        })
      }
    }
  }

  doHandleQuestion (e) {
    let text = e.target.value
    text = escapeHTML(text)
    const tLength = text.length
    this.setState({
      question: {
        text,
        length: tLength
      }
    })
  }

  doPrint () {
    this.setState({
      printing: true
    })
    window.print()
  }

  doSpreadInfo () {
    const { showSpreadInfo } = this.state

    this.setState({
      showSpreadInfo: !showSpreadInfo
    })
  }

  applyHoverAndView (cardID) {
    const newView = doHoverAndView(cardID, this.state)

    this.setState({
      ...newView
    })
  }

  doStart () {
    const { selected } = this.state
    const firstItem = selected[0]
    this.setState({
      walkthrough: 'next',
      hovered: firstItem,
      hoveredIndex: 0,
      viewed: [firstItem]
    })
  }

  doNext () {
    const nextState = doNext(this.state)
    this.setState({
      ...nextState
    })
  }

  doEnd () {
    const { selected } = this.state
    const viewed = selected
    this.setState({
      walkthrough: 'end',
      hovered: null,
      locked: false,
      viewed
    })
  }

  skipToResults () {
    const { stack, spread } = this.state
    let newStack = stack.slice()
    const newOrientation = []
    newStack = shuffle(newStack)
    newStack = shuffle(newStack)
    newStack = shuffle(newStack)
    const autoPicked = []
    const { max } = spread
    const currentSpreadHash = Math.ceil(new Date().getTime() / 1000)
    const currentSpreadUUID = getUUID()
    for (let i = 1; i <= max; i += 1) {
      let randomIndex = Math.ceil(Math.random() * newStack.length)

      if (newStack[randomIndex] === undefined) {
        if (newStack[randomIndex + 1] === undefined) {
          randomIndex -= 1
        } else {
          randomIndex += 1
        }
      }

      const newID = newStack[randomIndex].id
      autoPicked.push(newID)
      newOrientation.push(newStack[randomIndex].orientation)
      // remove from stack so we will not be selecting the same card
      newStack.splice(randomIndex, 1)
    }

    this.setState({
      selected: autoPicked,
      orientation: newOrientation,
      shuffled: 'done',
      locked: true,
      currentSpread: spread,
      allSelected: true,
      currentSpreadHash,
      currentSpreadUUID
    })
  }

  doSaveSpread () {
    const { showQuestion } = this.state
    this.setState({
      showQuestion
    })
  }

  doSaveResult () {
    const {
      currentSpread,
      currentSpreadHash,
      currentSpreadUUID,
      selected,
      orientation,
      question
    } = this.state
    const year = new Date().getFullYear()
    const yearDataKey = `haCker_history_${year}`
    let history = localStorage.getItem(yearDataKey)
    // let historyID = `haCker_history_${currentSpreadUUID}`
    // const historyData = localStorage.getItem(historyID)
    const result = {
      timestamp: currentSpreadHash,
      currentSpread: {
        id: currentSpread.id
      },
      selected,
      orientation,
      question
    }
    // const thisResultData = {
    //   timestamp: currentSpreadHash,
    //   uuid: currentSpreadUUID,
    //   question: {
    //     text: question,
    //     length: question === null ? 0 : question.length
    //   },
    //   starred: false
    // }
    // if (historyData !== null) {
    //   return
    // }

    if (history !== null) {
      history = JSON.parse(history)
      let { index } = history
      if (index === undefined) {
        index = []
      }
      // const historyNew = {
      //   index: [thisResultData, ...index],
      //   lastID: currentSpreadUUID
      // }
      // localStorage.setItem(yearDataKey, JSON.stringify(historyNew))
      // historyID = `haCker_history_${currentSpreadUUID}`
    } else {
      // const historyDefault = {
      //   index: [thisResultData],
      //   lastID: currentSpreadHash
      // }
      // localStorage.setItem(yearDataKey, JSON.stringify(historyDefault))
    }
    // const resultData = JSON.stringify(result)
    // localStorage.setItem(historyID, resultData)
    return { uuid: currentSpreadUUID, ...result }
  }

  render () {
    const {
      allSelected,
      selected: selectedFromState,
      orientation,
      spread,
      positionMap,
      shuffled,
      hovered,
      printing,
      viewing,
      viewed,
      walkthrough,
      cardsData,
      blur,
      stack,
      locked,
      currentSpread,
      showSpreadInfo,
      mode,
      currentSpreadUUID,
      historyYear
    } = this.state

    const deck = localStorage.getItem('deck') || 'cards'
    const selected = selectedFromState && selectedFromState.length > 0
      ? selectedFromState.map((item) => item.replace('_', ''))
      : selectedFromState

    if (spread.positions === undefined) {
      return (<div />)
    }

    if (cardsData === null) {
      return (<div />)
    }

    const StackData = stack
    const lockedClass = locked ? 'locked' : ''
    const currentSpreadID = currentSpread ? currentSpread.id : this.props.currentSpread.id
    // const positions = spread.positions
    // const stackMarkup = []
    const blurClass = blur
    const { question } = this.state
    const showControls = walkthrough === 'end' && hovered === null
    let qText = ''

    if (question !== null && question !== undefined && question.text !== undefined) {
      qText = question.text
    }

    let hasHovered = ''
    let bg = ''
    let stackStatus = shuffled
    let spreadClass = []
    let shuffleStatus = { }
    let selectStatus = { }
    // const spreadStatus = { };

    const walkthroughButtons = {
      start: {
        button: 'Start Reading',
        onClick: this.doStart
      },
      next: {
        button: 'Next',
        onClick: this.doNext
      },
      end: {
        button: 'End',
        onClick: this.doEnd
      }
    }

    if (currentSpreadID !== null && currentSpreadID.length > 0) {
      bg = <BoardBackground currentSpreadID={currentSpreadID} />
    }

    if (hovered !== null) {
      hasHovered = 'has-hovered'
    }
    if (allSelected) {
      stackStatus = `${stackStatus} no-tutorial`
      spreadClass = `spread-ready spread-${currentSpreadID}`
      shuffleStatus = { disabled: 'disabled' }
      selectStatus = { disabled: 'disabled' }
    } else {
      spreadClass = ''
      // spreadStatus = { disabled: 'disabled' };
    }

    let spreadInfoData = []
    if (spread.info !== undefined) {
      spreadInfoData = spread.info.split('\n')
        .map((item, index) => <p key={`p-${index * 10}`}>{item}</p>)
    }

    return (
      <div
        className={`tarot-board absolute-with-scroll ${blurClass} ${hasHovered}`}
        role='button'
        tabIndex='-1'
        aria-pressed='false'
      >
        <Share data={{ currentSpread, selected, orientation }} />
        <WalkthroughStart
          locked={locked}
          currentStage={walkthrough}
          handleWalkthrough={walkthroughButtons}
          doEnd={this.doEnd}
        />
        {(showSpreadInfo) && (
          <SpreadInfo
            spread={spread}
            doSpreadInfo={this.doSpreadInfo}
          />)}
        <div className='spread-name'>
          <h3 className='question'>
            {qText}
          </h3>
          <div className='spread'>{spread.name}</div>
        </div>
        <div
          id={currentSpreadID}
          className={`tarot-cards stack ${deck} ${stackStatus} ${lockedClass} ${spreadClass}`}
          // onMouseMove: this.props.updateShuffling,
          // onTouchMove: this.props.updateShuffling,
          onMouseMove={e => this.updateShuffling(e)}
          onTouchMove={e => this.updateShuffling(e)}
        >
          <Stack
            images={images}
            cardsData={cardsData}
            allSelected={allSelected}
            StackData={StackData}
            selected={selected}
            selectedOrientation={orientation}
            currentSpreadID={currentSpreadID}
            spread={spread}
            positionMap={positionMap}
            shuffled={shuffled}
            hovered={hovered}
            viewing={viewing}
            printing={printing}
            viewed={viewed}
            handleSelect={this.doSelect}
            updateShuffling={this.updateShuffling}
            applyHoverAndView={this.applyHoverAndView}
          />
        </div>
        {(showControls) &&
          (
            <Controls
              mode={mode}
              year={historyYear}
              uuid={currentSpreadUUID || getUUID()}
              doSpreadInfo={this.doSpreadInfo}
              doReset={this.doReset}
              doSaveResult={this.doSaveResult}
              doPrint={this.doPrint}
              spreadInfoData={spreadInfoData}
            />
          )}
        <Walkthrough
          stackStatus={stackStatus}
          qText={qText}
          shuffleStatus={shuffleStatus}
          selectStatus={selectStatus}
          spread={spread}
          selected={selected}
          handleQuestion={this.doHandleQuestion}
          enableShuffle={this.enableShuffle}
          doReady={this.doReady}
          skipToResults={this.skipToResults}
        />
        {bg}
      </div>
    )
  }
}

export default Cards
