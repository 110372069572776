import React from 'react'

import './index.css'

let drawInterval = null

const drawMatrix = (options = null) => {
  window.clearInterval(drawInterval)

  let defaults = {
    speed: 450,
    fontSize: 10,
    fontFamily: 'MS Gothic,arial',
    fontWeight: 'medium',
    leadFontWeight: 'bold',
    leadColor: '#b0ffee',
    fontColor: '#0F0',
    fadeColor: 'rgba(0, 0, 0, 0.05)'
  }

  // settings
  if (options !== null) {
    defaults = {
      ...defaults,
      options
    }
  }

  const {
    speed, fontSize, fontFamily, fontWeight,
    leadFontWeight, leadColor, fontColor, fadeColor
  } = defaults

  const rain = document.getElementById('matrix-rain')
  const ctx = rain.getContext('2d')

  const lead = document.getElementById('matrix-lead')
  const leadctx = lead.getContext('2d')

  // making the canvas full screen
  rain.height = window.innerHeight
  rain.width = window.innerWidth

  lead.height = window.innerHeight
  lead.width = window.innerWidth

  // inventory characters - taken from the unicode charset
  const inventory = ['ア', 'イ', 'ウ', 'エ', 'オ',
    'カ', 'キ', 'ク', 'ケ', 'コ',
    'サ', 'シ', 'ス', 'セ', 'ソ',
    'タ', 'チ', 'ツ', 'テ', 'ト',
    'ナ', 'ニ', 'ヌ', 'ネ', 'ノ',
    'ハ', 'ヒ', 'フ', 'ヘ', 'ホ',
    'マ', 'ミ', 'ム', 'メ', 'モ',
    'ヤ', 'ユ', 'ヨ', ' ',
    'ラ', 'リ', 'ル', 'レ', 'ロ',
    'ワ', 'ヰ', 'ヱ', 'ヲ',
    'ン', '゛', '゜', 'ー', 'ッ',
    '0,', '1', '2', '3', '4', '5', '6', '7', '8', '9',
    'H', 'a', 'C', 'e', 'r',
    '"', ';', '|', '(', ')', '\\', '/', '*',
    '<', '>', '@', '#', '$', '%', '^', '&', '-', '+', '`'
  ]

  const columns = rain.width / fontSize // number of columns for the rain
  const rows = rain.height / fontSize
  // an array of drops - one per column
  const drops = []
  // x below is the x coordinate
  // 1 = y co-ordinate of the drop(same for every drop initially)
  for (let x = 0; x < columns; x += 1) {
    drops[x] = Math.floor(Math.random() * rows)
  }

  // drawing the characters
  function draw () {
    // reset lead board
    leadctx.clearRect(0, 0, lead.width, lead.height)

    if (rain.width < window.innerWidth) {
      const newColumns = rain.width / fontSize // number of columns for the rain
      const newRows = rain.height / fontSize
      for (let x = 0; x < newColumns; x += 1) {
        drops.push(Math.floor(Math.random() * newRows))
      }
      rain.height = window.innerHeight
      rain.width = window.innerWidth

      lead.height = window.innerHeight
      lead.width = window.innerWidth
    }

    // Black BG for the canvas
    // translucent BG to show trail
    ctx.fillStyle = fadeColor
    ctx.fillRect(0, 0, rain.width, rain.height)

    ctx.fillStyle = fontColor // green text
    ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`

    leadctx.fillStyle = leadColor // green text
    leadctx.font = `${leadFontWeight} ${fontSize}px ${fontFamily}`

    let prevText = ''

    // looping over drops
    for (let i = 0; i < drops.length; i += 1) {
      // a random inventory character to print
      const text = inventory[Math.floor(Math.random() * inventory.length)]
      // x = i*fontSize, y = value of drops[i]*fontSize
      const thisX = i * fontSize
      const thisY = drops[i] * fontSize

      leadctx.fillText(text, thisX, thisY)

      leadctx.shadowColor = '#fff' // string
      // Color of the shadow;  RGB, RGBA, HSL, HEX, and other inputs are valid.
      leadctx.shadowOffsetX = 0 // integer
      // Horizontal distance of the shadow, in relation to the text.
      leadctx.shadowOffsetY = 0 // integer
      // Vertical distance of the shadow, in relation to the text.
      leadctx.shadowBlur = 10 // integer

      if (prevText !== '') {
        ctx.fillStyle = '#0F0' // green text
        ctx.fillText(prevText, thisX, thisY - fontSize)
      }

      prevText = text

      // sending the drop back to the top randomly after it has crossed the screen
      // adding a randomness to the reset to make the drops scattered on the Y axis
      if (drops[i] * fontSize > rain.height && Math.random() > 0.975) {
        drops[i] = 0
      }

      // incrementing Y coordinate
      drops[i] += 1
    }
  }

  drawInterval = setInterval(draw, speed)
}

class Matrix extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor (props) {
    super(props)

    this.state = {}
  }

  componentDidMount () {
    drawMatrix()
  }

  render () {
    return (
      <div
        className='matrix'
      >
        <canvas id='matrix-lead' />
        <canvas id='matrix-rain' />
      </div>
    )
  }
}

export default Matrix
