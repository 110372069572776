import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@material-ui/core/Modal'
import CircularProgress from '@material-ui/core/CircularProgress'
import ImagesWithStatusText from 'components/ImagesWithStatusText'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

function CircularProgressWithLabel (props) {
  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress variant='static' {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Typography variant='caption' component='div' color='textSecondary'>{`${Math.round(
          props.value
        )}%`}
        </Typography>
      </Box>
    </Box>
  )
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and static variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired
}

const DownloadModal = (props) => {
  const {
    open,
    type,
    handleModal,
    downloadLater,
    downloadClass,
    currentTotal,
    images,
    imagesTotal,
    newDownloads,
    downloadText,
    handleImageErrored,
    handleImageLoaded,
    doDownload
  } = props

  let downloadButtonLabel = downloadText

  if (downloadClass === 'downloading') {
    downloadButtonLabel = 'Download Initiated'
  }

  if (newDownloads <= 0 || downloadClass === 'completed') {
    downloadButtonLabel = 'Done'
  }

  return (
    <Modal
      open={open}
      onClose={() => handleModal('download', false)}
      aria-labelledby={type}
      aria-describedby={`${type}-desc`}
    >
      {type === 'download' && (
        <div className='modal-center'>
          {newDownloads <= 0 && (
            <div>
              <h2>Offline Mode Enabled</h2>
              <p>
                You have downloaded
                {' '}
                {imagesTotal}
                {' '}
                images required to enjoy the app offline.
              </p>
            </div>
          )}
          {newDownloads > 0 && (
            <div>
              <h2>Enable Offline Mode</h2>
              <p>
                This will sync
                {' '}
                {imagesTotal}
                {' '}
                image(s) and store them for offline use.
              </p>
            </div>
          )}
          <div className={`download-progress ${downloadClass}`}>
            {(newDownloads < 0) &&
              (
                <p>
                  Downloaded
                  {` ${currentTotal} of ${imagesTotal} images.`}
                </p>
              )}
            {(newDownloads >= 0) &&
              (
                <p>
                  Downloading :
                  {` ${currentTotal} of ${imagesTotal} images.`}
                </p>
              )}
            <CircularProgressWithLabel value={Math.round((currentTotal / imagesTotal) * 100)} color='secondary' />
            {(downloadClass === 'downloading') && (
              <ImagesWithStatusText
                images={images}
                handleImageErrored={handleImageErrored}
                handleImageLoaded={handleImageLoaded}
              />
            )}
          </div>
          <br />
          <br />
          <Button
            color='secondary'
            variant='outlined'
            onClick={() => doDownload()}
            onKeyPress={() => doDownload()}
          >
            {downloadButtonLabel}
          </Button>
          <br />
          <br />
          {newDownloads > 0 && (
            <Button
              color='secondary'
              variant='outlined'
              onClick={downloadLater}
              onKeyPress={downloadLater}
            >
              Download later
            </Button>
          )}
        </div>
      )}
    </Modal>
  )
}

export default DownloadModal
